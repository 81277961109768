import React from 'react'
import { Helmet } from 'react-helmet'

export default function Datenschutz() {
  return (
    <div className=''>
      <Helmet>
        <title data-react-helmet="true">Datenschutz</title>
        <meta name="robots" content="noodp,noydir" />
      </Helmet>
      <div className='mx-4 sm:mx-36 my-12 h-full rounded-md drop-shadow-md bg-white'>

        <div className='text-center text-4xl font-bold font-mono p-16 underline underline-offset-8'>
          Datenschutz
        </div>

        <div className='px-4 sm:px-32 py-16'>
          <>
            <p className='text-justify'>
              <b>Einleitung</b>
              <p>
                Wir haben diese Datenschutzerklärung verfasst, um Ihnen gemäß der Vorgaben der Datenschutz-Grundverordnung
                (EU) 2016/679 und anwendbaren nationalen Gesetzen zu erklären, welche personenbezogenen Daten
                wir als Verantwortliche verarbeiten, zukünftig verarbeiten werden und welche rechtmäßigen Möglichkeiten Sie haben.
              </p>
              <p >
                Diese Datenschutzerklärung soll Ihnen die wichtigsten Dinge einfach und transparent erklären.
                Soweit es der Transparenz förderlich ist, werden technische Begriffe erklärt, Links zu weiterführenden Informationen geboten.
                Wir informieren damit, dass wir im Rahmen unserer Geschäftstätigkeiten
                nur dann personenbezogene Daten verarbeiten, wenn eine entsprechende gesetzliche Grundlage gegeben ist.
              </p>
              <p >
                Wenn trotzdem Fragen bleiben, möchten wir Sie bitten, sich an die unten bzw. im Impressum genannte
                verantwortliche Stelle zu wenden. Unsere Kontaktdaten finden Sie selbstverständlich auch im Impressum.
              </p>
              <b>Anwendungsbereich</b>
              <p >
                Diese Datenschutzerklärung gilt für alle personenbezogenen Daten, die von uns oder von uns beauftragte Firmen (Auftragsverarbeiter) verarbeiten.
                Mit personenbezogenen Daten meinen wir Informationen im Sinne des Art. 4 Nr. 1 DSGVO wie zum Beispiel Name,
                E-Mail-Adresse und postalische Anschrift einer Person. Die Verarbeitung personenbezogener Daten sorgt dafür,
                dass wir unsere Dienstleistungen und Produkte anbieten und abrechnen können.
              </p>
              <b>Rechtsgrundlagen</b>
              <p >
                In der folgenden Datenschutzerklärung geben wir Ihnen transparente Informationen zu den Rechtsgrundlagen der Datenschutz-Grundverordnung,
                die uns ermöglichen, personenbezogene Daten zu verarbeiten. Was das EU-Recht betrifft, beziehen wir uns auf die VERORDNUNG
                2016/679 des EUROPÄISCHEN PARLAMENTS und des RATES vom 27. April 2016. Diese Datenschutz-Grundverordnung
                der EU können Sie selbstverständlich online auf EUR-Lex, unter
                https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679 nachlesen.
              </p>
              <br />
              <b className='underline underline-offset-1'>Wir verarbeiten Ihre Daten nur, wenn mindestens eine der folgenden Bedingungen zutrifft:</b>
              <br />
              <p >
                <b>Einwilligung</b> (Artikel 6 Absatz 1 lit. a DSGVO): <br /> Sie haben uns Ihre Einwilligung gegeben,
                Daten zu einem bestimmten Zweck zu verarbeiten. Ein Beispiel wäre die Speicherung Ihrer
                eingegebenen Daten eines Kontaktformulars.
              </p>
              <p >
                <b>Vertrag</b> (Artikel 6 Absatz 1 lit. b DSGVO): <br /> Um einen Vertrag oder vorvertragliche Verpflichtungen
                mit Ihnen zu erfüllen, verarbeiten wir Ihre Daten. Wenn wir zum Beispiel einen Kaufvertrag mit
                Ihnen abschließen, benötigen wir vorab personenbezogene Informationen.
              </p>
              <p >
                <b>Rechtliche Verpflichtung</b> (Artikel 6 Absatz 1 lit. c DSGVO): <br /> Wenn wir einer rechtlichen Verpflichtung unterliegen,
                verarbeiten wir Ihre Daten. Zum Beispiel sind wir gesetzlich verpflichtet Rechnungen für die Buchhaltung aufzuheben.
                Diese enthalten in der Regel personenbezogene Daten.
              </p>
              <p >
                <b>Berechtigte Interessen</b> (Artikel 6 Absatz 1 lit. f DSGVO): <br />
                Im Falle berechtigter Interessen,
                die Ihre Grundrechte nicht einschränken, behalten wir uns die Verarbeitung personenbezogener Daten vor.
                Wir müssen zum Beispiel gewisse Daten verarbeiten, um unsere Website sicher und wirtschaftlich
                effizient betreiben zu können. Diese Verarbeitung ist somit ein berechtigtes Interesse.
                Weitere Bedingungen wie die Wahrnehmung von Aufnahmen im öffentlichen Interesse und Ausübung
                öffentlicher Gewalt sowie dem Schutz lebenswichtiger Interessen treten bei uns in der Regel nicht auf.
                Soweit eine solche Rechtsgrundlage doch einschlägig sein sollte, wird diese an der entsprechenden Stelle ausgewiesen.
              </p>
              <b>Zusätzlich zu der EU-Verordnung gelten auch noch nationale Gesetze:</b>
              <p >
                In Österreich ist dies das Bundesgesetz zum Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten (Datenschutzgesetz), kurz DSG.
                In Deutschland gilt das Bundesdatenschutzgesetz, kurz BDSG.
              </p>
              <br />
              <p >
                <b>Kontaktdaten des Verantwortlichen</b>
                <br />

                Sollten Sie Fragen zum Datenschutz oder zur Verarbeitung personenbezogener Daten haben,
                finden Sie nachfolgend die Kontaktdaten der verantwortlichen Person bzw. Stelle:
                <ul>
                  <li>- S&S Technologies GmbH</li>
                  <li>- Michael Stücklschweiger</li>
                  <li>- Haspingerstrasse 4</li>
                  <li>- 5550 Radstadt</li>
                  <li>- Österreich</li>
                </ul>
                <b>Vertretungsberechtigt:</b>
                <ul>
                  <li>- Michael Stücklschweiger</li>
                  <li>- E-Mail: office@ss-technologies.at </li>
                  <li>- Telefon: +43 664 120 34 53</li>
                  <li>- Impressum: https://www.ss-technologies.at/imprint</li>
                </ul>
              </p>
              <p>
                <b>Kontaktdaten des Datenschutzbeauftragten:</b>
                <ul>
                  <li>- S&S Technologies GmbH</li>
                  <li>- Michael Stücklschweiger </li>
                  <li>- Haspingerstrasse 4</li>
                  <li>- 5550 Radstadt</li>
                  <li>- Österreich</li>
                  <li>- Telefon: +43 664 120 34 53</li>
                  <li>- E-Mail: michael.stuecklschweiger@ss-technologies.at</li>
                </ul>
              </p>
              <br />
              <b>Speicherdauer</b>
              <p >
                Wir speichern personenbezogene Daten nur so lange, wie es für die Bereitstellung
                unserer Dienstleistungen und Produkte unbedingt notwendig ist. Das bedeutet, dass wir personenbezogene Daten löschen,
                sobald der Grund für die Datenverarbeitung nicht mehr vorhanden ist. In einigen Fällen sind wir gesetzlich dazu verpflichtet, bestimmte
                Daten auch nach Wegfall des ursprüngliches Zwecks zu speichern, zum Beispiel zu Zwecken der Buchführung.
                <br />
                Sollten Sie die Löschung Ihrer Daten wünschen oder die Einwilligung zur Datenverarbeitung widerrufen,
                werden die Daten so rasch wie möglich und soweit keine Pflicht zur Speicherung besteht, gelöscht.
                <br />
                Über die konkrete Dauer der jeweiligen Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere Informationen dazu haben.
              </p>
              <br />
              <b>Rechte laut Datenschutz-Grundverordnung</b>
              <p >
                Gemäß Artikel 13, 14 DSGVO informieren wir Sie über die folgenden Rechte, die Ihnen zustehen,
                damit es zu einer fairen und transparenten Verarbeitung von Daten kommt:
                <ul>
                  <li>Sie haben laut Artikel 15 DSGVO ein Auskunftsrecht darüber, ob wir Daten von Ihnen verarbeiten.
                    Sollte das zutreffen, haben Sie Recht darauf eine Kopie der Daten zu erhalten und die folgenden Informationen zu erfahren:</li>
                  <ul>
                    <li>- zu welchem Zweck wir die Verarbeitung durchführen</li>
                    <li>- die Kategorien, also die Arten von Daten, die verarbeitet werden</li>
                    <li>- wer diese Daten erhält und wenn die Daten an Drittländer übermittelt werden, wie die Sicherheit garantiert werden kann</li>
                    <li>- wie lange die Daten gespeichert werden</li>
                    <li>- das Bestehen des Rechts auf Berichtigung, Löschung oder Einschränkung der Verarbeitung und dem Widerspruchsrecht gegen die Verarbeitung</li>
                    <li>- dass Sie sich bei einer Aufsichtsbehörde beschweren können (Links zu diesen Behörden finden Sie weiter unten)</li>
                    <li>- die Herkunft der Daten, wenn wir sie nicht bei Ihnen erhoben haben</li>
                    <li>- ob Profiling durchgeführt wird, ob also Daten automatisch ausgewertet werden, um zu einem persönlichen Profil von Ihnen zu gelangen</li>
                  </ul>
                  <li>Sie haben laut Artikel 16 DSGVO ein Recht auf Berichtigung der Daten.</li>
                  <li>Sie haben laut Artikel 17 DSGVO das Recht auf Löschung.</li>
                  <li>Sie haben laut Artikel 18 DSGVO das Recht auf Einschränkung der Verarbeitung.</li>
                  <li>Sie haben laut Artikel 20 DSGVO das Recht auf Datenübertragbarkeit.</li>
                  <li>Sie haben laut Artikel 21 DSGVO ein Widerspruchsrecht.</li>
                  <ul>
                    <li>- Wenn die Verarbeitung Ihrer Daten auf Artikel 6 Abs. 1 lit. e (öffentliches Interesse, Ausübung öffentlicher Gewalt) oder Artikel 6 Abs. 1 lit. f (berechtigtes Interesse) basiert,
                      können Sie gegen die Verarbeitung Widerspruch einlegen. Wir prüfen danach so rasch wie möglich, ob wir diesem Widerspruch rechtlich nachkommen können.</li>
                    <li>- Werden Daten verwendet, um Direktwerbung zu betreiben, können Sie jederzeit gegen diese Art der Datenverarbeitung widersprechen.
                      Wir dürfen Ihre Daten danach nicht mehr für Direktmarketing verwenden.</li>
                    <li>- Werden Daten verwendet, um Profiling zu betreiben, können Sie jederzeit gegen diese Art der Datenverarbeitung widersprechen.
                      Wir dürfen Ihre Daten danach nicht mehr für Profiling verwenden.</li>
                  </ul>
                  <li>Sie haben laut Artikel 22 DSGVO unter Umständen das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung
                    beruhenden Entscheidung unterworfen zu werden.</li>
                  <li>Sie haben laut Artikel 77 DSGVO das Recht auf Beschwerde. Das heißt, Sie können sich jederzeit bei der Datenschutzbehörde beschweren,
                    wenn Sie der Meinung sind, dass die Datenverarbeitung von personenbezogenen Daten gegen die DSGVO verstößt.</li>
                </ul>
              </p>
              <p >
                Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen
                Ansprüche in sonst einer Weise verletzt worden sind, können Sie sich bei der Aufsichtsbehörde beschweren.
                Diese ist für Österreich die Datenschutzbehörde, deren Website Sie unter https://www.dsb.gv.at/ finden.
                In Deutschland gibt es für jedes Bundesland einen Datenschutzbeauftragten. Für nähere Informationen können Sie
                sich an die Bundesbeauftragte für den Datenschutz und die Informationsfreiheit (BfDI) wenden. Für unser Unternehmen
                ist die folgende lokale Datenschutzbehörde zuständig:
              </p>
              <b>Österreich Datenschutzbehörde</b>
              <ul>
                <li>Leiterin: Mag. Dr. Andrea Jelinek </li>
                <li>Adresse: Barichgasse 40-42, 1030 Wien </li>
                <li>Telefonnr.: +43 1 52 152-0 </li>
                <li>E-Mail-Adresse: dsb@dsb.gv.at </li>
                <li>Website: https://www.dsb.gv.at/</li>
              </ul>
              <br />
              <b>Datenübertragung in Drittländer</b>
              <p >
                <ul>
                  <li>
                    Wir übertragen oder verarbeiten Daten nur dann in Länder außerhalb der EU (Drittländer),
                    wenn Sie dieser Verarbeitung zustimmen, dies gesetzlich vorgeschrieben ist oder vertraglich
                    notwendig und in jedem Fall nur soweit dies generell erlaubt ist. Ihre Zustimmung ist in
                    den meisten Fällen der wichtigste Grund, dass wir Daten in Drittländern verarbeiten lassen.
                    Die Verarbeitung personenbezogener Daten in Drittländern wie den USA, kann bedeuten, dass personenbezogene Daten
                    auf unerwartete Weise verarbeitet und gespeichert werden.
                  </li>
                  <li>
                    Wir weisen ausdrücklich darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit
                    kein angemessenes Schutzniveau für den Datentransfer in die USA besteht. Die Datenverarbeitung
                    durch US-Dienste (wie beispielsweise Google Analytics) kann dazu führen, dass gegebenenfalls Daten
                    nicht anonymisiert verarbeitet und gespeichert werden. Ferner können gegebenenfalls US-amerikanische
                    staatliche Behörden Zugriff auf einzelne Daten nehmen. Zudem kann es vorkommen,
                    dass erhobene Daten mit Daten aus anderen Diensten desselben Anbieters, sofern
                    Sie ein entsprechendes Nutzerkonto haben, verknüpft werden. Nach Möglichkeit versuchen
                    wir Serverstandorte innerhalb der EU zu nutzen, sofern das angeboten wird.
                  </li>
                  <li>
                    Wir informieren Sie an den passenden Stellen dieser Datenschutzerklärung genauer über Datenübertragung in Drittländer, sofern diese zutrifft.
                  </li>
                </ul>
              </p>
              <br />
              <p >
                <b>Sicherheit der Datenverarbeitung</b>
                <ul>
                  <li>
                    Um personenbezogene Daten zu schützen, haben wir sowohl technische als auch organisatorische Maßnahmen umgesetzt.
                    Wo es uns möglich ist, verschlüsseln oder pseudonymisieren wir personenbezogene Daten.
                    Dadurch machen wir es im Rahmen unserer Möglichkeiten so schwer wie möglich,
                    dass Dritte aus unseren Daten auf persönliche Informationen schließen können.
                  </li>
                  <li>
                    Art. 25 DSGVO spricht hier von “Datenschutz durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen”
                    und meint damit, dass man sowohl bei Software also auch Hardware immer an Sicherheit denkt und entsprechende Maßnahmen setzt.
                    Im Folgenden gehen wir, noch auf konkrete Maßnahmen ein.
                  </li>
                </ul>
                <b>TLS-Verschlüsselung mit https</b>
                <ul>
                  <li>
                    Wir verwenden HTTPS (Hypertext Transfer Protocol Secure), um Daten abhörsicher im Internet zu übertragen.
                  </li>
                  <li>
                    Damit haben wir eine zusätzliche Sicherheitsschicht eingeführt und erfüllen den Datenschutz durch Technikgestaltung
                    (Artikel 25 Absatz 1 DSGVO). Durch den Einsatz von TLS (Transport Layer Security), einem Verschlüsselungsprotokoll
                    zur sicheren Datenübertragung im Internet, können wir den Schutz vertraulicher Daten sicherstellen. Sie erkennen die
                    Benutzung dieser Absicherung der Datenübertragung am kleinen Schlosssymbol links oben im Browser,
                    links von der Internetadresse und der Verwendung des Schemas "https" als Teil unserer Internetadresse.
                  </li>
                </ul>
              </p>
              <b>Kommunikation</b>
              <p >
                <ul>
                  <li>Wenn Sie mit uns Kontakt aufnehmen und per Telefon, E-Mail oder Online-Formular kommunizieren, kann es zur Verarbeitung personenbezogener Daten kommen.</li>
                  <li>Die Daten werden für die Abwicklung und Bearbeitung Ihrer Frage und des damit zusammenhängenden Geschäftsvorgangs verarbeitet.
                    Die Daten während eben solange gespeichert bzw. solange es das Gesetz vorschreibt.</li>
                  <li><b>Betroffene Personen</b></li>
                  <li>Von den genannten Vorgängen sind alle betroffen, die über die von uns bereit gestellten Kommunikationswege den Kontakt zu uns suchen.</li>
                  <li><b>Telefon</b></li>
                  <li>Wenn Sie uns anrufen, werden die Anrufdaten auf dem jeweiligen Endgerät und beim eingesetzten Telekommunikationsanbieter pseudonymisiert gespeichert.
                    Außerdem können Daten wie Name und Telefonnummer im Anschluss per E-Mail versendet und zur Anfragebeantwortung gespeichert werden.
                    Die Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</li>
                  <li><b>E-Mail</b></li>
                  <li>Wenn Sie mit uns per E-Mail kommunizieren, werden Daten gegebenenfalls auf dem jeweiligen Endgerät
                    gespeichert und es kommt zur Speicherung von Daten auf dem E-Mail-Server. Die Daten werden gelöscht, sobald der Geschäftsfall
                    beendet wurde und es gesetzliche Vorgaben erlauben.</li>
                  <li><b>Online Formulare</b></li>
                  <li>Wenn Sie mit uns mittels Online-Formular kommunizieren, werden Daten auf unserem Webserver gespeichert und gegebenenfalls an eine E-Mail-Adresse von uns
                    weitergeleitet. Die Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</li>
                  <li><b>Rechtsgrundlagen</b></li>
                  <li>Die Verarbeitung der Daten basiert auf den folgenden Rechtsgrundlagen:</li>
                  <ul>
                    <li>Art. 6 Abs. 1 lit. a DSGVO (Einwilligung):<br />
                      Sie geben uns die Einwilligung Ihre Daten zu speichern und weiter für den Geschäftsfall betreffende Zwecke zu verwenden</li>
                    <li>Art. 6 Abs. 1 lit. b DSGVO (Vertrag):<br />
                      Es besteht die Notwendigkeit für die Erfüllung eines Vertrags mit Ihnen, einem Auftragsverarbeiter oder wir müssen die Daten für vorvertragliche Tätigkeiten, wie z. B. die Vorbereitung eines Angebots, verarbeiten.</li>
                    <li>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen): Wir wollen Kundenanfragen und geschäftliche Kommunikation in einem professionellen Rahmen betreiben.
                      Dazu sind gewisse technische Einrichtungen wie z. B. E-Mail-Programme, Exchange-Server und Mobilfunkbetreiber notwendig,
                      um die Kommunikation effizient betreiben zu können.</li>
                  </ul>
                </ul>
              </p>
              <p >
                <br />
                <b>Cookies</b>
                <ul>
                  <li>Unsere Website verwendet HTTP-Cookies, um nutzerspezifische Daten zu speichern.</li>
                  <li>Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser. Bekannte Browser sind beispielsweise Chrome, Safari, Firefox, Internet Explorer
                    und Microsoft Edge. Die meisten Websites speichern kleine Text-Dateien in Ihrem Browser. Diese Dateien nennt man Cookies.</li>
                  <li> HTTP-Cookies sind kleine Dateien, die von unserer Website auf Ihrem Computer gespeichert werden. Diese Cookie-Dateien
                    werden automatisch im Cookie-Ordner, untergebracht. Ein Cookie besteht aus einem Namen und einem Wert. Bei der Definition
                    eines Cookies müssen zusätzlich ein oder mehrere Attribute angegeben werden.</li>
                  <li>Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise Sprache oder persönliche Seiteneinstellungen.
                    Wenn Sie unsere Seite wieder aufrufen, übermittelt Ihr Browser die „userbezogenen“ Informationen an unsere Seite zurück.
                    Dank der Cookies weiß unsere Website, wer Sie sind und bietet Ihnen die Einstellung, die Sie gewohnt sind. In einigen
                    Browsern hat jedes Cookie eine eigene Datei, in anderen wie beispielsweise Firefox sind alle Cookies in einer einzigen Datei gespeichert.</li>
                  <li>Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies. Erstanbieter-Cookies werden direkt von unserer Seite erstellt,
                    Drittanbieter-Cookies werden von Partner-Websites (z.B. Google Analytics) erstellt. Jedes Cookie ist individuell zu bewerten,
                    da jedes Cookie andere Daten speichert. Auch die Ablaufzeit eines Cookies variiert von ein paar Minuten bis hin zu ein paar Jahren.
                    Cookies sind keine Software-Programme und enthalten keine Viren, Trojaner oder andere „Schädlinge“.
                    Cookies können auch nicht auf Informationen Ihres PCs zugreifen.</li>
                  <li>So können zum Beispiel Cookie-Daten aussehen:</li>
                  <li>Name: _ga</li>
                  <li>Wert: GA2.7.1326744211.1524521128900-9</li>
                  <li>Verwendungszweck: Unterscheidung der Websitebesucher</li>
                  <li>Ablaufzeit: nach 1 Jahr</li>
                </ul>
                <b>Welche Arten von Cookies gibt es?</b>
                <p>Die Frage welche Cookies wir im Speziellen verwenden, hängt von den verwendeten Diensten ab und wird in den folgenden Abschnitten
                  der Datenschutzerklärung geklärt. An dieser Stelle möchten wir kurz auf die verschiedenen Arten von HTTP-Cookies eingehen.</p>
                <p>Man kann 4 Arten von Cookies unterscheiden:</p>
                <ul>
                  <li><b>Unerlässliche Cookies</b><br />
                    Diese Cookies sind nötig, um grundlegende Funktionen der Website sicherzustellen. Zum Beispiel braucht es diese Cookies,
                    wenn ein User ein Produkt in den Warenkorb legt, dann auf anderen Seiten weitersurft und später erst zur Kasse geht.
                    Durch diese Cookies wird der Warenkorb nicht gelöscht, selbst wenn der User sein Browserfenster schließt.</li>
                  <li>Unsere Seite verwendet folgenden unerlässlichen Cookies:
                  </li>
                  <li>Name: oyul</li>
                  <li>Wert: True</li>
                  <li>Verwendunszweck: Enthält die Zustimmung, zum Verwenden von Cookies, Google Analytics und Adsense</li>
                  <li>Ablaufzeift: 1 Jahr</li>
                  <li>Name: sosig</li>
                  <li>Wert: True</li>
                  <li>Verwendunszweck: Enthält die von Ihnen eingegebenen Rechnungsdaten, ermöglicht wiederherstellung der eingegebenen Daten bei schließen und wiederöffnen der Seite.</li>
                  <li>Ablaufzeift: 1 Jahr</li><br></br>
                  <li><b>Zweckmäßige Cookies</b><br />
                    Diese Cookies sammeln Informationen über das Userverhalten und ob der User etwaige Fehlermeldungen bekommt.
                    Zudem werden mithilfe dieser Cookies auch die Ladezeit und das Verhalten der Website bei verschiedenen Browsern gemessen.</li>
                  <li><b>Zielorientierte Cookies</b><br />
                    Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit. Beispielsweise werden eingegebene Standorte, Schriftgrößen oder Formulardaten gespeichert.</li>
                  <li><b>Werbe-Cookies</b><br />
                    Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu dem User individuell angepasste Werbung zu liefern.</li>
                  <p>
                    <li><b><br />Zweck der Verarbeitung über Cookies</b><br />
                      Der Zweck ist letztendlich abhängig vom jeweiligen Cookie. Mehr Details dazu finden Sie weiter unten bzw. beim Hersteller der Software, die das Cookie setzt.</li>
                    <li><b>Welche Daten werden verarbeitet?</b><br />
                      Welche Daten in Cookies gespeichert werden, kann man leider nicht verallgemeinern, aber wir werden Sie im Rahmen
                      der folgenden Datenschutzerklärung über die verarbeiteten bzw. gespeicherten Daten informieren.</li>
                    <li><b>Speicherdauer von Cookies</b><br />
                      Die Speicherdauer hängt vom jeweiligen Cookie ab und wird weiter unter präzisiert. Manche Cookies werden nach weniger als einer Stunde gelöscht, andere können mehrere Jahre auf einem Computer gespeichert bleiben.
                      Sie haben außerdem selbst Einfluss auf die Speicherdauer. Sie können über ihren Browser sämtliche Cookies jederzeit manuell
                      löschen (siehe auch unten “Widerspruchsrecht”). Ferner werden Cookies, die auf einer Einwilligung beruhen,
                      spätestens nach Widerruf Ihrer Einwilligung gelöscht, wobei die Rechtmäßigkeit der Speicherung bis dahin unberührt bleibt.</li>
                    <li><b>Widerspruchsrecht</b></li>
                    <li>Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst. Unabhängig von welchem Service oder welcher Website die Cookies stammen, haben Sie immer die Möglichkeit Cookies zu löschen, zu deaktivieren oder nur teilweise zuzulassen. Zum Beispiel können Sie Cookies von Drittanbietern blockieren, aber alle anderen Cookies zulassen.</li>
                    <li>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie das Cookie erlauben oder nicht.</li>
                    <li><b>Rechtsgrundlage</b></li>
                    <li>Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin ist festgehalten, dass das Speichern von Cookies eine Einwilligung (Artikel 6 Abs. 1 lit. a DSGVO) von Ihnen verlangt. In Österreich erfolgte die Umsetzung dieser Richtlinie in § 96 Abs. 3 des Telekommunikationsgesetzes (TKG). In Deutschland wurden die Cookie-Richtlinien nicht als nationales Recht umgesetzt. Stattdessen erfolgte die Umsetzung dieser Richtlinie weitgehend in § 15 Abs.3 des Telemediengesetzes (TMG).</li>
                    <li>Für unbedingt notwendige Cookies, auch soweit keine Einwilligung vorliegt, bestehen berechtigte Interessen (Artikel 6 Abs. 1 lit. f DSGVO), die in den meisten Fällen wirtschaftlicher Natur sind. Wir möchten den Besuchern der Website eine angenehme Benutzererfahrung bescheren und dafür sind bestimmte Cookies oft unbedingt notwendig.
                      Soweit nicht unbedingt erforderliche Cookies zum Einsatz kommen, geschieht dies nur im Falle Ihrer Einwilligung. Rechtsgrundlage ist insoweit Art. 6 Abs. 1 lit. a DSGVO.
                    </li>
                  </p>
                </ul>
              </p>
              <br />
              <p >
                <ul>
                  <li><b>Web Analytics</b></li>
                  <p>Wir verwenden auf unserer Website Web Analytics. Dabei werden Daten gesammelt, die der jeweilige Analytic-Tool-Anbieter speichert, verwaltet und verarbeitet. Mit Hilfe der Daten werden Analysen über das Nutzerverhalten auf unserer Website erstellt und uns als Websitebetreiber zur Verfügung gestellt. Zusätzlich bieten die meisten Tools verschiedene Testmöglichkeiten an. So können wir etwa testen, welche Angebote oder Inhalte bei unseren Besuchern am besten ankommen. Dafür zeigen wir Ihnen für einen begrenzten Zeitabschnitt zwei verschiedene Angebote. Nach dem Test (sogenannter A/B-Test) wissen wir, welches Produkt bzw. welcher Inhalt unsere Websitebesucher interessanter finden. Für solche Testverfahren, wie auch für andere Analytics-Verfahren, können auch Userprofile erstellt werden und die Daten in Cookies gespeichert werden.</p>
                  <li><b>Warum verwenden wir Web Analytics?</b></li>
                  <li>Wir wollen für unsere Branche das beste Webangebot liefern. Um dieses Ziel zu erreichen, wollen wir einerseits das beste und interessanteste Angebot anbieten. Mit Hilfe von Webanalyse-Tools können wir das Verhalten unserer Websitebesucher genauer untersuchen und dann entsprechend unser Webangebot zu verbessern. All diese Informationen helfen uns die Website zu optimieren und anzupassen.</li>
                  <li><b>Welche Daten werden verarbeitet?</b></li>
                  <li>Welche Daten genau gespeichert werden, hängt von den verwendeten Analyse-Tools ab. In der Regel wird zum Beispiel gespeichert, welche Inhalte Sie auf unserer Website ansehen, auf welche Buttons oder Links Sie klicken, wann Sie eine Seite aufrufen, welchen Browser sie verwenden, mit welchem Gerät (PC, Tablet, Smartphone usw.) Sie die Website besuchen oder welches Computersystem Sie verwenden. Wenn Sie damit einverstanden waren, dass auch Standortdaten erhoben werden dürfen, können auch diese durch den Webanalyse-Tool-Anbieter verarbeitet werden.</li>
                  <li>Zudem wird auch Ihre IP-Adresse gespeichert. Gemäß der Datenschutz-Grundverordnung (DSGVO) sind IP-Adressen personenbezogene Daten. Ihre IP-Adresse wird allerdings in der Regel pseudonymisiert gespeichert. Für den Zweck der Tests, der Webanalyse und der Weboptimierung werden grundsätzlich keine direkten Daten, wie etwa Ihr Name, Ihr Alter, Ihre Adresse oder Ihre E-Mail-Adresse gespeichert. All diese Daten werden, sofern sie erhoben werden, pseudonymisiert gespeichert.</li>
                  <li><b>Widerspruchsrecht</b></li>
                  <li>Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung zur Verwendung von Cookies bzw. Drittanbietern zu widerrufen. Das funktioniert entweder über unser Cookie-Management-Tool oder über andere Opt-Out-Funktionen.</li>
                  <li><b>Rechtsgrundlage</b></li>
                  <li>
                    Der Einsatz von Web-Analytics setzt Ihre Einwilligung voraus, welche wir mit unserem Cookie Popup eingeholt haben. Diese Einwilligung stellt laut Art. 6 Abs. 1 lit. a DSGVO die Rechtsgrundlage für die Verarbeitung personenbezogener Daten dar.
                    Zusätzlich zur Einwilligung besteht von unserer Seite ein berechtigtes Interesse daran, das Verhalten der Websitebesucher zu analysieren und so unser Angebot zu verbessern. Mit Hilfe von Web-Analytics erkennen wir Fehler der Website, können Attacken identifizieren und die Wirtschaftlichkeit verbessern. Die Rechtsgrundlage dafür ist Art. 6 Abs. 1 lit. f DSGVO. Wir setzen die Tools nur ein, soweit sie eine Einwilligung erteilt haben.
                  </li>
                </ul>
                <ul>
                  <li><b>Google Analytics</b></li>
                  <li>Wir verwenden auf unserer Website das Analyse-Tracking Tool Google Analytics (GA) des amerikanischen Unternehmens Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich. Google Analytics sammelt Daten über Ihre Handlungen auf unserer Website. Mithilfe der Berichte, die wir von Google Analytics erhalten, können wir unsere Website und unser Service besser an Ihre Wünsche anpassen.</li>
                  <li>Google Analytics ist ein Trackingtool, das der Datenverkehrsanalyse unserer Website dient.</li>
                  <li>
                    <p>
                      <ul>
                        <li>Google verarbeitet die Daten und erstellt Berichte über Ihr Userverhalten. Dabei kann es sich um folgende Berichte handeln:</li>
                        <li>Zielgruppenberichte</li>
                        <li>Anzeigeberichte</li>
                        <li>Akquisitionsberichte</li>
                        <li>Verhaltensberichte</li>
                        <li>Conversionsberichte</li>
                        <li>Echtzeitberichte</li>
                      </ul>
                    </p>
                  </li>
                </ul>
                <ul>
                  <li><b>Warum verwenden wir Google Analytics?</b></li>
                  <li>Wir wollen eine bestmögliche Erfahrung bieten. Die Statistiken und Daten von Google Analytics helfen uns dies zu erreichen.</li>
                  <li>Die statistisch ausgewerteten Daten zeigen uns Probleme unserer Website, welche wir nutzen könen um unsere Seite verbessern.</li>
                  <li><b>Welche Daten werden von Google Analytics gespeichert?</b></li>
                  <li>Google Analytics erstellt einen zufälligen Tracking-Code, die mit Ihrem Browser-Cookie verbunden ist. So erkennt Sie Google Analytics als neuen User. Wenn Sie das nächste Mal unsere Seite besuchen, werden Sie als „wiederkehrender“ User erkannt. Alle gesammelten Daten werden gemeinsam mit dieser User-ID gespeichert. So ist es erst möglich pseudonyme Userprofile auszuwerten.</li>
                  <li>Um mit Google Analytics unsere Website analysieren zu können, wird eine Property-ID dem Tracking-Code zugefügt. Je nach verwendeter Property werden Daten unterschiedlich lange gespeichert.</li>
                  <li>Durch Kennzeichnungen wie Cookies und App-Instanz-IDs werden Ihre Interaktionen auf der Website gemessen. Wenn Sie auch andere Google-Systeme (Google-Konto) nützen, können über Google Analytics generierte Daten mit Drittanbieter-Cookies verknüpft werden. Google gibt keine Google Analytics-Daten weiter, außer wir als Websitebetreiber genehmigen dies. Zu Ausnahmen kann es kommen, wenn es gesetzlich erforderlich ist.</li>
                  <li>Folgende Cookies werden von Google Analytics eingesetzt:</li><br></br>
                  <ul>
                    <li>Name: _ga</li>
                    <li>Wert: 2.1326744211.152312184319-5</li>
                    <li>Verwendungszweck: Speichert die User-ID um User zu unterscheiden.</li>
                    <li>Ablaufzeit: 2 Jahre</li><br></br>
                    <li>Name: _gid</li>
                    <li>Wert: 2.1687193234.152312184319-1</li>
                    <li>Verwendungszweck: Dient auch der User Unterscheidung</li>
                    <li>Ablaufzeit: 24 Stunden</li><br></br>
                    <li>Name: _gat_gtag_UA_&lt;property-id&gt;</li>
                    <li>Wert: 1</li>
                    <li>Verwendungszweck: Begrenzt die Anforderungsrate</li>
                    <li>Ablaufzeit: 1 Minute</li><br></br>
                    <li>Name: AMP_TOKEN</li>
                    <li> Wert:  keine Angabe</li>
                    <li>Verwendungszweck: Cookie hat einen Token, mit dem eine User ID vom AMP-Client-ID-Dienst abgerufen werden kann.</li>
                    <li>Ablaufzeit: 30 Sekunden bis 1 Jahr</li><br></br>
                    <li>Name: __utma</li>
                    <li>Wert: 1564498958.1564498958.1564498958.1</li>
                    <li>Verwendungszweck: Mit diesem Cookie kann man Ihr Verhalten auf der Website verfolgen und die Leistung messen.</li>
                    <li>Ablaufzeit: 2 Jahre</li><br></br>
                    <li>Name: __utmt</li>
                    <li>Wert: 1</li>
                    <li>Verwendungszweck: Begrenzt die Anforderungsrate</li>
                    <li>Ablaufzeit: 10 Minuten</li><br></br>
                    <li>Name: __utmb</li>
                    <li>Wert: 3.10.1564498958</li>
                    <li>Verwendungszweck: Wird benutzt um eine neue Sitzungen zu bestimmen.</li>
                    <li>Ablaufzeit: 30 Minuten</li><br></br>
                    <li>Name: __utmc</li>
                    <li>Wert: 167421564</li>
                    <li>Verwendungszweck: Session-Cookie wird verwendet um neue Sitzungen für wiederkehrende Besucher festzulegen</li>
                    <li>Ablaufzeit: Bei Schließung der Seite</li><br></br>
                    <li>Name: __utmz</li>
                    <li>Wert: m|utmccn=(referral)|utmcmd=referral|utmcct=/</li>
                    <li>Verwendungszweck: Das Cookie wird verwendet, um die Quelle des Users zu identifizieren.</li>
                    <li>Ablaufzeit: 6 Monate</li><br></br>
                    <li>Name: __utmv</li>
                    <li>Wert: keine Angabe</li>
                    <li>Verwendungszweck: Wird verwendet um benutzerdefinierte Daten zu speichern.</li>
                    <li>Ablaufzeit: 2 Jahre</li><br></br>
                    <li>Diese Aufzählung kann keinen Anspruch auf Vollständigkeit erheben, da Google die Wahl ihrer Cookies immer wieder verändert.</li>
                    <li>Hier zeigen wir Ihnen einen Überblick über die wichtigsten Daten, die mit Google Analytics erhoben werden:</li><br></br>
                    <li>Heatmaps: Google ersrtellt Heatmaps an. Heatmaps zeigen jene Bereiche an, die Sie anklicken. So bekommen wir Informationen, welche Bereiche unsere Seite genutzt werden.</li><br></br>
                    <li>Sitzungsdauer: Sitzungsdauer ist die Zeit, die Sie auf unserer Seite verbringen.</li><br></br>
                    <li>Absprungrate: Ist die Rate, wenn Sie sich von unserer Website nur eine Seite ansehen und dann die Website wieder verlassen.</li><br></br>
                    <li>Kontoerstellung: Wenn Sie auf unserer Website ein Konto erstellen, erhebt Google Analytics diese Daten.</li><br></br>
                    <li>IP-Adresse: Die IP-Adresse wird nur in gekürzter Form gespeichert, damit keine eindeutige Zuordnung möglich ist.</li><br></br>
                    <li>Standort: Ermöglicht die ungefähre Bestimmung ihres Standorts.</li><br></br>
                    <li>Technische Informationen: Folgende Daten können gespeichert werden Browsertyp, Ihr Internetanbieter oder Ihre Bildschirmauflösung.</li><br></br>
                    <li>Herkunftsquelle: Über welche Website oder welche Werbung Sie auf unsere Seite gekommen sind.</li><br></br>
                    <li>Weitere Daten sind Kontaktdaten, etwaige Bewertungen, das Abspielen von Medien, das Teilen von Inhalten oder das Hinzufügen zu den Favoriten. <br></br> Die Aufzählung hat keinen Vollständigkeitsanspruch und dient nur zu einer allgemeinen Orientierung der Datenspeicherung durch Google Analytics.</li><br></br>
                  </ul>
                  <ul>
                    <li><b>Speicherdauer und Speicherort</b></li>
                    <li>
                      Google hat Server auf der ganzen Welt. Ein großer Teil der Server befindet sich in Amerika und folglich werden Ihre Daten meist auf amerikanischen Servern gespeichert.
                      Die Aufbewahrungsdauer der Daten hängt von den verwendeten Eigentschaften ab. Bei der Verwendung der neueren Google Analytics 4-Properties ist die Aufbewahrungsdauer Ihrer Userdaten auf 14 Monate fix eingestellt. Für andere sogenannte Ereignisdaten gibt es die Möglichkeit eine Aufbewahrungsdauer von 2 Monaten oder 14 Monaten zu wählen.
                      Bei Universal Analytics-Properties ist standardisiert eine Aufbewahrungsdauer von 26 Monaten standardisiert eingestellt. Weiters gibt es auch die Option, dass Daten erst dann gelöscht werden, wenn Sie innerhalb des von uns gewählten Zeitraums nicht mehr unsere Website besuchen. In diesem Fall wird die Aufbewahrungsdauer jedes Mal zurückgesetzt,
                      wenn Sie unsere Website innerhalb des festgelegten Zeitraums wieder besuchen. Wenn der festgelegte Zeitraum abgelaufen ist, werden einmal im Monat die Daten gelöscht. Diese Aufbewahrungsdauer gilt für Ihre Daten, die mit Cookies, Usererkennung und Werbe-IDs verknüpft sind.
                    </li>
                    <li><b>Verhinderung der Datenspeicher, Löschung der Daten</b></li>
                    <li>Nach dem Datenschutzrecht der Europäischen Union haben Sie das Recht, Auskunft über Ihre Daten zu erhalten, sie zu aktualisieren, zu löschen oder einzuschränken. Mithilfe des Browser-Add-ons zur Deaktivierung von Google Analytics-JavaScript wird verhindert, dass Google Analytics Ihre Daten verwendet.</li><br></br>
                  </ul>
                  <ul>
                    <li><b>Rechtsgrundlage</b></li>
                    <li>Der Einsatz von Google Analytics setzt Ihre Einwilligung voraus, welche wir mit unserem Cookie Popup eingeholt haben. Diese Einwilligung stellt laut Art. 6 Abs. 1 lit. a DSGVO die Rechtsgrundlage für die Verarbeitung personenbezogener Daten dar.</li>
                    <li>Zusätzlich zur Einwilligung besteht von unserer Seite ein berechtigtes Interesse daran, dass Verhalten der Websitebesucher zu analysieren und so unsere Seite zu verbessern. Mit Hilfe von Google Analytics erkennen wir Fehler der Website und können die Wirtschaftlichkeit erhöhen. Die Rechtsgrundlage dafür ist Art. 6 Abs. 1 lit. f DSGVO. Wir setzen Google Analytics nur ein, wenn Sie ihre Zustimmung erteilt haben.
                      Google verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht. Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten oder einer Datenweitergabe dorthin verwendet Google sogenannte Standardvertragsklauseln  Art. 46. Abs. 2 und 3 DSGVO. Standardvertragsklauseln sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europäischen Datenschutzstandards entsprechen, wenn diese in Drittländer (wie beispielsweise in die USA) überliefert und dort gespeichert werden.
                    </li>
                    <li><b>Auftragsverarbeitungsvertrag Google Analytics</b></li>
                    <li>
                      Wir haben im Sinne des Artikels 28 DSGVO mit Google einen Auftragsverarbeitungsvertrag abgeschlossen. Dieser Vertrag ist gesetzlich vorgeschrieben, weil Google in unserem Auftrag personenbezogene Daten verarbeitet.
                      Darin wird geklärt, dass Google Daten, die es von uns erhalten, nur nach unserer Weisung verarbeiten darf und die DSGVO einhalten muss.
                    </li>
                    <li><b>Google Analytics Berichte zu demografischen Merkmalen und Interessen</b></li>
                    <li>Wir haben in Google Analytics die Funktionen für Werbeberichte eingeschaltet. Die Berichte zu demografischen Merkmalen und Interessen enthalten Angaben zu Alter, Geschlecht und Interessen.</li>
                    <li><b>Google Analytics im Einwilligungsmodus</b></li>
                    <li>Abhängig von Ihrer Einwilligung werden im sogenannten Einwilligungsmodus personenbezogene Daten von Ihnen durch Google Analytics verarbeitet.
                      Sie können wählen, ob Sie Google-Analytics-Cookies zustimmen oder nicht. Damit wählen Sie auch, welche Daten Google Analytics von Ihnen verarbeitet darf.
                      Diese erhobenen Daten werden hauptsächlich dafür verwendet, Messungen über das Userverhalten auf der Website durchzuführen, zielgerichtete Werbung
                      auszuspielen und uns Web-Analyseberichte zu liefern. In der Regel willigen Sie der Datenverarbeitung durch Google über ein Cookie-Consent-Tool ein.
                      Wenn Sie der Datenverarbeitung nicht einwilligen, werden nur aggregierte Daten erfasst und verarbeitet. Das bedeutet, Daten können einzelnen Usern
                      nicht zugeordnet werden und es entsteht somit kein Userprofil von Ihnen. Sie können auch nur der statistischen Messung zustimmen. Dabei werden
                      keine personenbezogenen Daten verarbeitet und folglich nicht für Werbungen oder Werbemesserfolge verwendet.</li>
                    <li><b>Google Analytics IP-Anonymisierung</b></li>
                    <li>Wir haben auf dieser Webseite die IP-Adressen-Anonymisierung von Google Analytics implementiert. Diese Funktion wurde von Google entwickelt,
                      damit diese Webseite die geltenden Datenschutzbestimmungen und Empfehlungen der lokalen Datenschutzbehörden einhalten kann,
                      wenn diese eine Speicherung der vollständigen IP-Adresse untersagen. Die Anonymisierung bzw. Maskierung der IP findet statt,
                      sobald die IP-Adressen im Google Analytics-Datenerfassungsnetzwerk eintreffen und bevor eine Speicherung oder Verarbeitung der Daten stattfindet.</li>
                    <li><b>Google Analytics Signals</b></li>
                    <li>Wir haben in Google Analytics die Google-Signale aktiviert. So werden die bestehenden Google-Analytics-Funktionen (Werbeberichte, Remarketing, gerätübergreifende Berichte und Berichte zu Interessen und demografische Merkmale) aktualisiert, um zusammengefasste und anonymisierte Daten von Ihnen zu erhalten, sofern Sie personalisierte Anzeigen in Ihrem Google-Konto erlaubt haben.
                      Das besondere daran ist, dass es sich dabei um ein Cross-Device-Tracking handelt. Das heißt Ihre Daten können geräteübergreifend analysiert werden. Durch die Aktivierung von Google-Signale werden Daten erfasst und mit dem Google-Konto verknüpft. Google kann dadurch zum Beispiel erkennen, wenn Sie auf unsere Webseite über ein Smartphone ein Produkt ansehen und erst später über einen Laptop das Produkt kaufen. Dank der Aktivierung von Google-Signale können wir gerätübergreifende Remarketing-Kampagnen starten, die sonst in dieser Form nicht möglich wären. Remarketing bedeutet, dass wir Ihnen auch auf anderen Webseiten unser Angebot zeigen können.
                      In Google Analytics werden zudem durch die Google-Signale weitere Besucherdaten wie Standort, Suchverlauf, YouTube-Verlauf und Daten über Ihre Handlungen auf unserer Webseite, erfasst. Wir erhalten dadurch von Google bessere Werbeberichte und nützlichere Angaben zu Ihren Interessen und demografischen Merkmalen. Dazu gehören Ihr Alter, welche Sprache sie sprechen, wo Sie wohnen oder welchem Geschlecht Sie angehören. Weiters kommen auch noch soziale Kriterien wie Ihr Beruf, Ihr Familienstand oder Ihr Einkommen hinzu. All diese Merkmal helfen Google Analytics Personengruppen bzw. Zielgruppen zu definieren.
                      Die Berichte helfen uns auch Ihr Verhalten, Ihre Wünsche und Interessen besser einschätzen zu können. Dadurch können wir unsere Dienstleistungen und Produkte für Sie optimieren und anpassen. Diese Daten laufen standardmäßig nach 26 Monaten ab. Bitte beachten Sie, dass diese Datenerfassung nur erfolgt, wenn Sie personalisierte Werbung in Ihrem Google-Konto zugelassen haben. Es handelt sich dabei immer um zusammengefasste und anonyme Daten und nie um Daten einzelner Personen. In Ihrem Google-Konto können Sie diese Daten verwalten bzw. auch löschen.</li><br></br>
                  </ul>
                </ul>
              </p>
              <ul>
                <li><b>Online-Marketing Datenschutzerklärung</b></li>
                <li>Unter Online-Marketing bezeichnet man alle Maßnahmen, die online durchgeführt werden, um Marketingziele wie etwa die Steigerung der Markenbekanntheit
                  oder einen Geschäftsabschluss zu erreichen. Weiters zielen unserer Online-Marketing-Maßnahmen darauf ab, Menschen auf unsere Website aufmerksam zu
                  machen. Um unser Angebot vielen interessierten Menschen zeigen zu können, betreiben wir Online-Marketing. Damit wir Online-Marketing effizient und zielgerecht einsetzen können,
                  werden auch personenbezogene Daten gespeichert und verarbeitet. Die Daten helfen uns einerseits unsere Inhalte nur wirklich jenen Personen zu zeigen, die sich dafür auch
                  interessieren und andererseits können wir den Werbeerfolg unserer Online-Marketing-Maßnahmen messen.</li>
                <li><b>Welche Daten werden verarbeitet?</b></li>
                <li>Damit unser Online-Marketing funktioniert und der Erfolg der Maßnahmen gemessen werden kann, werden Userprofile erstellt und Daten beispielsweise in Cookies gespeichert. Mit Hilfe dieser Daten können wir nicht nur Werbung in klassischer Hinsicht schalten, sondern auch direkt auf unserer Website. Dafür gibt es verschiedene Drittanbieter-Tools, die diese Funktionen anbieten und entsprechend auch Daten von Ihnen sammeln und speichern. In den benannten Cookies wird zum Beispiel gespeichert, welche Webseiten Sie auf unserer Website besucht haben, wie lange Sie diese Seiten angesehen haben, welche Links oder Buttons Sie klicken oder von welcher Website sie zu uns gekommen sind. Zusätzlich können auch technische Informationen gespeichert werden. Etwa Ihre IP-Adresse, welchen Browser Sie nutzen, von welchem Endgerät Sie unsere Website besuchen oder die Uhrzeit, wann Sie unserer Website aufgerufen haben und wann Sie sie wieder verlassen haben. Wenn Sie eingewilligt haben, dass wir auch Ihren Standort bestimmen dürfen, können wir auch diesen speichern und verarbeiten.
                  Ihre IP-Adresse wird in pseudonymisierter Form gespeichert. Eindeutige Daten, die Sie als Person direkt identifizieren, wie etwa Name, Adresse oder E-Mail-Adresse, werden im Rahmen der Werbe- und Online-Marketing-Verfahren auch nur in pseudonymisierter Form gespeichert. Wir können Sie also nicht als Person identifizieren.
                  Die Cookies können unter Umständen auch auf anderen Websites, die mit denselben Werbetools arbeiten, eingesetzt, analysiert und für Werbezwecke verwendet werden. Die Daten können dann auch auf den Servern der Werbetools-Anbieter gespeichert werden.
                  In Ausnahmefällen können auch eindeutige Daten in den Userprofilen gespeichert werden. Zu dieser Speicherung kommt es etwa, wenn Sie Mitglied eines Social-Media-Kanals sind, dass wir für unsere Online-Marketing-Maßnahmen nutzen und das Netzwerk bereits früher eingegangene Daten mit dem Userprofil verbindet.
                  Bei allen von uns verwendeten Werbetools, werden Ihre Daten auf deren Servern gespeichert, erhalten wir immer nur zusammengefasste Informationen und nie Daten, die Sie als Einzelperson erkennbar machen. Die Daten zeigen lediglich, wie gut gesetzte Werbemaßnahmen funktionierten. Anhand der Analysen können wir unser Werbeangebot in Zukunft verbessern und noch genauer an die Bedürfnisse und Wünsche interessierter Personen anpassen.</li>
                <li><b>Dauer der Datenverarbeitung</b></li>
                <li>Über die Dauer der Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere Informationen dazu haben. Generell verarbeiten wir personenbezogene Daten nur so lange wie es für die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist. Daten, die in Cookies gespeichert werden, werden unterschiedlich lange gespeichert. In den jeweiligen Datenschutzerklärungen der einzelnen Anbieter erhalten Sie in der Regel genaue Informationen über die einzelnen Cookies, die der Anbieter verwendet.</li>
                <li><b>Widerspruchsrecht</b></li>
                <li>Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung zur Verwendung von Cookies bzw. Drittanbietern zu widerrufen. Das funktioniert entweder über unser Cookie-Management-Tool oder über andere Opt-Out-Funktionen. Zum Bespiel können Sie auch die Datenerfassung durch Cookies verhindern, indem Sie in Ihrem Browser die Cookies verwalten, deaktivieren oder löschen. Die Rechtmäßigkeit der Verarbeitung bis zum Widerruf bleibt unberührt.</li>
                <li><b>Rechtsgrundlage</b></li>
                <li>Wenn Sie eingewilligt haben, dass Drittanbieter eingesetzt werden dürfen, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut Art. 6 Abs. 1 lit. a DSGVO die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Online-Marketing-Tools vorkommen kann, dar.
                  Von unserer Seite besteht zudem ein berechtigtes Interesse, Online-Marketing-Maßnahmen in anonymisierter Form zu messen, um mithilfe der gewonnenen Daten unser Angebot und unsere Maßnahmen zu optimieren. Die dafür entsprechende Rechtsgrundlage ist Art. 6 Abs. 1 lit. f DSGVO. Wir setzen die Tools gleichwohl nur ein, wenn Sie eine Einwilligung erteilt haben.
                  Informationen zu speziellen Online-Marketing-Tools erhalten Sie in den folgenden Abschnitten.
                </li>
                <li><b>Google AdSense</b></li>
                <li>Wir verwenden auf dieser Website Google AdSense. Das ist ein Anzeigenprogramm der Firma Google Inc. In Europa ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich. Mit Google AdSense können wir auf dieser Webseite Werbeanzeigen einblenden, die zu unserem Thema passen. So bieten wir Ihnen Anzeigen, die im Idealfall einen richtigen Mehrwert für Sie darstellen. Im Zuge dieses Datenschutztextes über Google AdSense erklären wir Ihnen, warum wir Google AdSense auf unserer Website verwenden, welche Ihrer Daten verarbeitet und gespeichert werden und wie Sie diese Datenspeicherung unterbinden können.
                  Über Google AdSense werden Werbeanzeigen auf Websites, wie zum Beispiel auf unserer, ausgespielt. Google überprüft anhand Ihrer Interessen bzw. Ihres Userverhaltens und anhand unseres Angebots, welche Werbeanzeigen für unsere Website und für unsere User geeignet sind. An dieser Stelle wollen wir auch gleich erwähnen, dass wir für die Auswahl der Werbeanzeigen nicht verantwortlich sind. Wir bieten mit unserer Website lediglich die Werbefläche an. Die Auswahl der angezeigten Werbung trifft Google.
                  Das Betreiben einer hochwertigen Website erfordert viel Hingabe und großen Einsatz. Im Grunde sind wir mit der Arbeit an unserer Website nie fertig. Wir versuchen stets unsere Seite zu pflegen und so aktuell wie möglich zu halten. Natürlich wollen wir mit dieser Arbeit auch einen wirtschaftlichen Erfolg erzielen. Darum haben wir uns für Werbeanzeigen als Einnahmequelle entschieden. Das Wichtigste für uns ist allerdings, Ihren Besuch auf unserer Website durch diese Anzeigen nicht zu stören. Mithilfe von Google AdSense wird Ihnen nur Werbung angeboten, die zu unseren Themen und Ihren Interessen passt.
                  Ähnlich wie bei der Google-Indexierung für eine Website, untersucht ein Bot den entsprechenden Content und die entsprechenden Angebote auf der Seite. Dann werden die Werbeanzeigen inhaltlich angepasst und präsentiert. Neben den inhaltlichen Überschneidungen zwischen Anzeige und Website-Angebot unterstützt AdSense auch interessensbezogenes Targeting. Das bedeutet, dass Google auch Ihre Daten dazu verwendet, um auf Sie zugeschnittene Werbung anzubieten.
                  Damit Google AdSense eine maßgeschneiderte, auf Sie angepasste Werbung anzeigen kann, werden unter anderem Cookies verwendet.
                  In AdSense sollen Cookies bessere Werbung ermöglichen. Die Cookies enthalten keine personenidentifizierbaren Daten. Hierbei ist allerdings zu beachten, dass Google Daten wie zum Beispiel “Pseudonyme Cookie-IDs” oder IP-Adressen als nicht personenidentifizierbare Informationen ansieht. Im Rahmen der DSGVO können diese Daten allerdings als personenbezogene Daten gelten. Google AdSense sendet nach jeder Impression, jedem Klick und jeder anderen Aktivität, die zu einem Aufruf der Google-AdSense-Server führt, ein Cookie an den Browser. Sofern der Browser das Cookie akzeptiert, wird es dort gespeichert.
                  Drittanbieter können im Rahmen von AdSense unter Umständen Cookies in Ihrem Browser platzieren und auslesen bzw. Web-Beacons verwenden, um Daten zu speichern, die sie durch die Anzeigenbereitstellung auf der Website erhalten. Als Web-Beacons bezeichnet man kleine Grafiken, die eine Logdatei-Analyse und eine Aufzeichnung der Logdatei machen. Diese Analyse ermöglicht eine statistische Auswertung für das Online-Marketing.
                  Google kann über diese Cookies bestimmte Informationen über Ihr Userverhalten auf unserer Website sammeln. Dazu zählen:
                  Informationen wie Sie mit einer Anzeige umgehen (Klicks, Impression, Mausbewegungen)
                  Informationen, ob in Ihrem Browser schon eine Anzeige zu einem früheren Zeitpunkt erschienen ist. Diese Daten helfen dabei, Ihnen eine Anzeige nicht öfter anzuzeigen.
                  Dabei analysiert Google die Daten zu den angezeigten Werbemitteln und Ihre IP-Adresse und wertet diese aus. Google verwendet die Daten in erster Linie, um die Effektivität einer Anzeige zu messen und das Werbeangebot zu verbessern. Diese Daten werden nicht mit personenbezogenen Daten, die Google möglicherweise über andere Google-Dienste von Ihnen hat, verknüpft.</li><br></br>
                <li>Im Folgenden stellen wir Ihnen Cookies vor, die Google AdSense für Trackingzwecke verwendet. Hierbei beziehen wir uns auf eine Test-Website, die ausschließlich Google AdSense installiert hat:</li><br></br>
                <li>Name: uid</li>
                <li>Wert: 891269189134650030-8</li>
                <li>Verwendungszweck: Das Cookie wird unter der Domain adform.net gespeichert. Es stellt eine eindeutig zugewiesene, maschinell generierte User-ID bereit und sammelt Daten über die Aktivität auf unserer Webseite.</li>
                <li>Ablaufzeit: 2 Monate</li><br></br>
                <li>Name: C</li>
                <li>Wert: 1</li>
                <li>Verwendungszweck: Dieses Cookie identifiziert, ob Ihr Browser Cookies akzeptiert. Das Cookie wird unter der Domain track.adform.net gespeichert.</li>
                <li>Ablaufzeit: 1 Monat</li><br></br>
                <li>Name: cid</li>
                <li>Wert: 8912691894970695056,0,0,0,0</li>
                <li>Verwendungszweck: Dieses Cookie wird unter der Domain track.adform.net gespeichert, steht für Client-ID und wird verwendet, um die Werbung für Sie zu verbessern. Es kann relevantere Werbung an den Besucher weiterleiten und hilft, die Berichte über die Kampagnenleistung zu verbessern.</li>
                <li>Ablaufzeit: 2 Monate</li><br></br>
                <li>Name: IDE</li>
                <li>Wert: zOtj4TWxwbFDjaATZ2TzNaQmxrU134650030-1</li>
                <li>Verwendungszweck: Das Cookie wird unter der Domain doubleclick.net gespeichert. Es dient dazu, Ihre Aktionen nach der Anzeige bzw. nach dem Klicken der Anzeige zu registrieren. Dadurch kann man messen, wie gut eine Anzeige bei unseren Besuchern ankommt.</li>
                <li>Ablaufzeit: 1 Monat</li><br></br>
                <li>Name: test_cookie</li>
                <li>Wert: keine Angabe</li>
                <li>Verwendungszweck: Mithilfe des „test_cookies“ kann man überprüfen, ob Ihr Browser überhaupt Cookies unterstützt. Das Cookie wird unter der Domain doubleclick.net gespeichert.</li>
                <li>Ablaufzeit: 1 Monat</li><br></br>
                <li>Name: CT592996</li>
                <li>Wert: 733366</li>
                <li>Verwendungszweck: Wird unter der Domain adform.net gespeichert. Das Cookie wird gesetzt sobald Sie auf eine Werbeanzeige klicken. Genauere Informationen über die Verwendung dieses Cookies konnten wir nicht in Erfahrung bringen.</li>
                <li>Ablaufzeit: 1 Stunde</li><br></br>
                <li>Diese Aufzählung kann keinen Anspruch auf Vollständigkeit erheben, da Google die Wahl seiner Cookies immer wieder verändert.</li>
                <li><b>Speicherdauer, Speicherort</b></li>
                <li>Google erfasst Ihre IP-Adresse und verschiedene Aktivitäten, die Sie auf der Website ausführen. Cookies speichern diese Informationen zu den Interaktionen auf unserer Website. Laut Google sammelt und speichert das Unternehmen die angegebenen Informationen auf sichere Weise auf den hauseigenen Google-Servern in den USA.
                  Wenn Sie kein Google-Konto haben bzw. nicht angemeldet sind, speichert Google die erhobenen Daten mit einer eindeutigen Kennung (ID) meist auf Ihrem Browser. Die in Cookies gespeicherten eindeutigen IDs dienen beispielsweise dazu, personalisierte Werbung zu gewährleisten. Wenn Sie in einem Google-Konto angemeldet sind, kann Google auch personenbezogene Daten erheben.
                  Einige der Daten, die Google speichert, können Sie jederzeit wieder löschen. Viele Informationen, die in Cookies gespeichert sind, werden automatisch nach einer bestimmten Zeit wieder gelöscht. Es gibt allerdings auch Daten, die von Google über einen längeren Zeitraum gespeichert werden. Dies ist dann der Fall, wenn Google aus wirtschaftlichen oder rechtlichen Notwendigkeiten, gewisse Daten über einen unbestimmten längeren Zeitraum speichern muss.</li>
                <li><b>Rechtsgrundlage</b></li>
                <li>Wenn Sie eingewilligt haben, dass Google AdSense eingesetzt werden darf, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut Art. 6 Abs. 1 lit. a DSGVO die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Google AdSense vorkommen kann, dar.
                  Von unserer Seite besteht zudem ein berechtigtes Interesse, Google AdSense zu verwenden, um unser Online-Service und unsere Marketingmaßnahmen zu optimieren. Die dafür entsprechende Rechtsgrundlage ist Art. 6 Abs. 1 lit. f DSGVO. Wir setzen Google AdSense gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.
                  Google verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der Datenverarbeitung einhergehen.
                  Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten oder einer Datenweitergabe dorthin verwendet Google sogenannte Standardvertragsklauseln Art. 46. Abs. 2 und 3 DSGVO. Standardvertragsklauseln sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europäischen
                  Datenschutzstandards entsprechen, wenn diese in Drittländer (wie beispielsweise in die USA) überliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich Google, bei der Verarbeitung Ihrer relevanten Daten, das europäische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden.
                  Diese Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission.</li>
              </ul>
              <br />
              <p><b>Externe Links</b></p>
              <ul>
                <li>Die Webseite enthält so genannte externe Links (Verlinkungen) zu anderen Webseiten, auf deren Inhalt wir keinen Einfluss haben. Aus diesem Grund können wir für diese Inhalte auch keine Gewähr übernehmen. Für die Inhalte und Richtigkeit der bereitgestellten Informationen ist der jeweilige Anbieter der verlinkten Webseite verantwortlich. Zum Zeitpunkt der Verlinkung waren keine Rechtsverstöße erkennbar. Bei Bekanntwerden einer solchen Rechtsverletzung wird der Link umgehend entfernt.</li>
              </ul>
              <br />
              <p>
                <b>Erklärung verwendeter Begriffe</b>
              </p>
              <p><b>Einwilligung</b></p>
              <ul>
                <li>Begriffsbestimmung nach Artikel 4 der DSGVO</li>
                <li>Im Sinne dieser Verordnung bezeichnet der Ausdruck:</li>
                <li>„Einwilligung“ der betroffenen Person jede freiwillig für den bestimmten Fall, in informierter Weise und unmissverständlich abgegebene Willensbekundung in Form einer Erklärung oder einer sonstigen eindeutigen bestätigenden Handlung, mit der die betroffene Person zu verstehen gibt, dass sie mit der Verarbeitung der sie betreffenden personenbezogenen Daten einverstanden ist</li>
                <li>Erläuterung: In der Regel erfolgt bei Websites eine solche Einwilligung über ein Cookie-Consent-Tool. Sie kennen das bestimmt. Immer wenn Sie erstmals eine Website besuchen, werden Sie meist über einen Banner gefragt, ob Sie der Datenverarbeitung zustimmen bzw. einwilligen. Meist können Sie auch individuelle Einstellungen treffen und so selbst entscheiden, welche Datenverarbeitung Sie erlauben und welche nicht. Wenn Sie nicht einwilligen, dürfen auch keine personenbezogene Daten von Ihnen verarbeitet werden. Grundsätzlich kann eine Einwilligung natürlich auch schriftlich, also nicht über ein Tool, erfolgen.</li>
              </ul>
              <p>
                <b>Personenbezogene Daten</b>
                <ul>
                  <li>Begriffsbestimmung nach Artikel 4 der DSGVO</li>
                  <li>Im Sinne dieser Verordnung bezeichnet der Ausdruck:</li>
                  <li>„personenbezogene Daten“ alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person (im Folgenden „betroffene Person“) beziehen; als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind, identifiziert werden kann</li>
                  <li>Erläuterung: Personenbezogene Daten sind also all jene Daten, die Sie als Person identifizieren können. Das sind in der Regel Daten wie etwa:</li>
                  <ul>
                    <li>- Name</li>
                    <li>- Adresse</li>
                    <li>- E-Mail-Adresse</li>
                    <li>- Post-Anschrift</li>
                    <li>- Telefonnummer</li>
                    <li>- Geburtsdatum</li>
                    <li>- Kennnummern wie Sozialversicherungsnummer, Steueridentifikationsnummer, Personalausweisnummer oder Matrikelnummer</li>
                    <li>- Bankdaten wie Kontonummer, Kreditinformationen, Kontostände uvm.</li>
                  </ul>
                  <li>Laut Europäischem Gerichtshof (EuGH) zählt auch Ihre IP-Adressen zu den personenbezogenen Daten. IT-Experten können anhand Ihrer IP-Adresse zumindest den ungefähren Standort Ihres Geräts und in weiterer Folge Sie als Anschlussinhabers feststellen. Daher benötigt auch das Speichern einer IP-Adresse eine Rechtsgrundlage im Sinne der DSGVO.</li>
                </ul>
              </p>
            </p>
          </>
        </div >
      </div >
    </div >
  )
}