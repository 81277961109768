import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

export default class Footer extends Component {
    render() {
        return (
            <footer className="text-center lg:text-left bg-gray-100 text-gray-600">
                <div className="sm:mx-16 md:mx-32 lg:mx-64 py-10 text-center md:text-left">
                    <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-8">
                        <div className="px-4 md:w-[30vw]">
                            <h2 className="uppercase font-semibold mb-4 flex items-center justify-center md:justify-start ">
                                Rechnung Online Erstellen | 100% Kostenlos
                            </h2>
                            <p>
                                <h3 className='underline mb-2'>Ein einfaches free-to-use Tool um online Rechnungen zu erstellen.</h3>
                                <ul className='list-disc'>
                                    <li>
                                        <h4>Mit unserem Rechnungsgenerator können Sie schnell und einfach Rechnungen generieren.</h4>
                                    </li>
                                    <li>
                                        <h5>Füllen Sie die <NavLink to={'/hilfe'}><u >Vorlage</u> </NavLink> mit Ihre Firmendaten und Ihrem Logo aus und erstellen Sie Ihre Rechnung noch heute!</h5>
                                    </li>
                                    <li>
                                        <h6>Beachten Sie alle <NavLink to={'/rechnungen-richtig-schreiben'}><u >Richtlinien und gesetzlichen Vorgaben</u> </NavLink>
                                            um eine gesetzeskonforme Rechnung zu garantieren und rechtliche Probleme zu vermeiden.</h6>
                                    </li>
                                </ul>
                            </p>
                        </div>

                        <div className="">
                            <h6 className="uppercase font-semibold mb-4 flex justify-center md:justify-start">
                                Wie erstelle ich Rechnungen?
                            </h6>

                            <p className="mb-4">
                                <NavLink className="text-gray-600" to='/rechnungen-richtig-schreiben'> Was muss auf die Rechnung? </NavLink>
                            </p>

                            <p className="mb-4">
                                <NavLink className="text-gray-600" to='/hilfe'> Hilfe </NavLink>
                            </p>

                            <p className="mb-4">
                                <NavLink className="text-gray-600" to='/impressum'> Impressum </NavLink>
                            </p>

                            <p className="mb-4">
                                <NavLink className="text-gray-600" to='/datenschutz'> Datenschutz </NavLink>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="text-center p-6 bg-gray-200">
                    <span>{new Date().getFullYear()} © </span>
                    <a href="https://www.ss-technologies.at" target="_blank" rel="noreferrer" className="text-gray-600 font-semibold">S&S Technologies GmbH</a>
                </div>
            </footer>
        )
    }
}
