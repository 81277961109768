import React, { useState } from 'react'
import { TabView, TabPanel } from 'primereact/tabview';
import { Helmet } from "react-helmet"

import 'primeicons/primeicons.css';
import "primereact/resources/primereact.min.css";                  //core css

export default function Guide() {


  const [activeIndex, setActiveIndex] = useState(0);

  const einleitung = <>
    Für jeden Unternehmer und Selbstständigen gehört das Rechnungenschreiben zum Arbeitsalltag. <br />
    Jedoch ist das leider nicht so einfach wie es scheint. Bei Gesetzeskonformen Rechnungen gibt es einigs zu beachten.
    Im Folgenden werden alle Pflichtangaben die auf eine Rechnung gehören aufgelistet, und es wird auch auf die konkreten Gesetzestexte verlinkt!
  </>

  const ustg14 = <a href='https://www.gesetze-im-internet.de/ustg_1980/__14.html' target="_blank" rel="noreferrer"><u>UStG §14<i className='pi pi-external-link text-xs' /></u></a>

  const ustg19 = <a href='https://www.gesetze-im-internet.de/ustg_1980/__19.html' target="_blank" rel="noreferrer"><u>UStG §19<i className='pi pi-external-link text-xs' /></u></a>

  const wko = <a href='https://www.wko.at/service/steuern/Erfordernisse-einer-Rechnung.html' target="_blank" rel="noreferrer"><u>WKO<i className='pi pi-external-link text-xs' /></u></a>

  const ris = <a href='https://www.gesetze-im-internet.de/ustg_1980/__19.html' target="_blank" rel="noreferrer"><u>UStG §11<i className='pi pi-external-link text-xs' /></u></a>

  return (
    <div className=''>

      <Helmet>
        <title data-react-helmet="true">Was muss auf eine gesetzeskonforme Rechnung?</title>
        <meta
          name="description"
          content="Richtig Rechnungen schreiben mit unserer Vorlage. Beachten Sie immer alle Gesetztesvorgaben um rechtliche Probleme mit Ihren Rechnungen zu vermeide."
          data-react-helmet="true"
        />

        <meta
          name="keywords"
          content="Rechnung erstellen, Rechnung Vorlage, Rechnung Kleingewerbe, Was muss auf eine Rechnung, Rechnung richtig schreiben, Pflichtangaben auf einer Rechnung, Pflichtangaben, Rechnung Pflichtangaben"
          data-react-helmet="true"
        />
        <meta name="robots" content="noodp,noydir" />
      </Helmet>

      <div className='mx-[5vw] my-12 h-full rounded-md drop-shadow-md bg-white flex items-center justify-center font-mono'>
        <div className='pt-6'>
          <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} >
            <TabPanel header={
              <div className={activeIndex === 0 ? "text-4xl text-center p-4 w-[40vw] text-neutral-800 border-b-4 border-neutral-800" : "text-4xl text-center p-4 w-[40vw] text-gray-500 border-b-4 border-gray-400"}>AT</div>
            }>
              <div className='py-8 w-[80vw] sm:w-[60vw]'>
                <div className='py-4'>
                  {einleitung}
                </div>
                <h1 className='text-2xl py-4 font-bold'>
                  Pflichtangaben auf einer Rechnung
                </h1>

                <div>
                  <div className='pb-4'>
                    Vor allem muss aus steuerrechtlicher Sicht darauf geachtet, dass Rechnungen alle Pflichtangaben beinhalten. Ansonsten kann es bei zu rechtlichen Problemen kommen. Folgende Pflichtangaben enthält eine Rechnung laut {wko} und {ris}:
                  </div>
                  <ul className='list-disc mx-6'>
                    <li>Name und Anschrift des liefernden oder leistenden Unternehmers</li>
                    <li>Name und Anschrift des Leistungsempfängers</li>
                    <li>Menge und handelsübliche Bezeichnung der Gegenstände bzw. Art und Umfang der Leistungen</li>
                    <li>Tag/Zeitraum der Lieferung oder sonstigen Leistung</li>
                    <li>Entgelt für die Lieferung/sonstige Leistung und der anzuwendende Steuersatz bzw. bei Steuerbefreiung einen Hinweis auf diese</li>
                    <li>den auf das Entgelt entfallenden Steuerbetrag</li>
                    <li>Ausstellungsdatum (wenn dieses gleich ist mit dem Tag der Lieferung oder sonstigen Leistung, genügt der Vermerk “Rechnungsdatum ist gleich Liefer- bzw. Leistungsdatum“)</li>
                    <li>Fortlaufende Nummer</li>
                    <li>Umsatzsteueridentifikationsnummer (UID-Nummer) des Ausstellers der Rechnung</li>
                    <li>UID-Nummer des Leistungsempfängers (auf Rechnungen mit einem Gesamtbetrag über EUR 10.000,- inkl. USt, weiters wenn die Steuerschuld auf den Leistungsempfänger übergeht - Reverse Charge sowie bei innergemeinschaftlichen Lieferungen).</li>
                    <li>bei Anwendung der Differenzbesteuerung hat ein Hinweis auf diese zu erfolgen (z.B. Antiquitätenhandel)</li>
                    <li>Wird die Rechnung in einer anderen Währung als Euro ausgestellt, ist der Steuerbetrag zusätzlich in Euro anzugeben</li>
                  </ul>

                  <h2 className='text-2xl font-bold py-4'>
                    Rechnung schreiben nach Unternehmensart
                  </h2>

                  <h2 className='text-xl font-semibold py-4'>
                    Rechnung schreiben als Unternehmer oder Seblständiger
                  </h2>
                  <div className='pb-4'>

                    Als Unternemher muss man für jeden Umsatz an einen Unternehmer oder eine juristische Person, die nicht Unternehmer ist, ohne dass dafür ein ausdrückliches Verlangen des Kunden erforderlich ist, eine Rechnung ausstellen. Dies gilt auch für steuerbefreite Umsätze bzw. für Umsätze mit Reverse Charge. Dabei müssen die oben genannten Pflichtangaben eingehalten werden.
                  </div>

                  <h2 className='text-xl font-semibold py-4'>
                    Kleinbetragsrechnung
                  </h2>
                  <div className='pb-4'>
                    Bei Rechnungen deren Gesamtbetrag 400 EUR inkl. Umsatzsteuer nicht übersteigt, genügen folgende Angaben:

                    <ul className='list-disc mx-6'>
                      <li>Name und Anschrift des liefernden oder leistenden Unternehmers</li>
                      <li>Menge und handelsübliche Bezeichnung der Gegenstände bzw. Art und Umfang der Leistungen</li>
                      <li>Tag/Zeitraum der Lieferung oder sonstigen Leistung</li>
                      <li>Entgelt und Steuerbetrag in einer Summe</li>
                      <li>Steuersatz</li>
                      <li>Ausstellungsdatum</li>
                    </ul>
                    <br />
                    Die Vereinfachungsbestimmungen für Rechnungen bis zu 400 EUR gelten nicht für innergemeinschaftliche Lieferungen. Diese Rechnungen müssen jedenfalls einen Hinweis auf die Steuerbefreiung sowie die UID-Nummer des liefernden Unternehmers und des Abnehmers enthalten.
                  </div>

                  <h2 className='text-xl font-semibold py-4'>
                    Rechnung schreiben ohne Gewerbe
                  </h2>
                  <div className='pb-4'>
                    Rechnungen schreiben ohne ein Gewerbe zu besitzen ist grundsätzlich möglich. Die Rechnung muss die gleichen Pflichtangaben beinhalten wie eine Rechnung die von einem Gewerbetreibenden ausgestellt wird.
                  </div>

                  <h2 className='text-2xl font-bold py-4'>
                    Rechnungen bei Vermietung
                  </h2>
                  <div className='pb-4'>
                    Die Erfordernisse einer Rechnung hätten bei Mietverträgen oder ähnlichen Verträgen über eine Dauerleistung einen enormen administrativen Aufwand bei monatlicher Rechnungserstellung zur Folge.
                    <br />
                    Die Finanzverwaltung anerkennt in diesen Fällen eine „Dauerrechnung“ für eine monatliche Mietvorschreibung, welche in der Regel einmal jährlich ausgestellt wird. Als Leistungszeitraum kann z.B. der erste Monat im Jahr angeführt werden mit dem Zusatz, dass die Vorschreibung bis zum Ergehen einer neuen Vorschreibung gilt. Enthält diese Rechnung alle gesetzlichen Bestandteile und wird die Miete bezahlt, steht auch für die anderen Monate der Vorsteuerabzug zu.
                  </div>

                  <h2 className='text-2xl font-bold py-4'>
                    Rechnungen bei innergemeinschaftlichen Lieferungen
                  </h2>
                  <div className='pb-4'>
                    Bei innergemeinschaftlichen Lieferungen Charge hat der leistende Unternehmer:
                    <ul className='list-disc mx-6'>
                      <li>die UID-Nummer des Leistungsempfängers anzugeben (Nummer sollte nach Stufe 2 auf Richtig- und Zugehörigkeit überprüft werden</li>
                      <li>Anstelle des Steuerbetrags bzw. Steuersatzes ist ausdrücklich auf die Steuerfreiheit der innergemeinschaftlichen Lieferung hinzuweisen</li>
                    </ul>
                    <br />
                    Sollte fälschlicherweise Umsatzsteuer ausgewiesen werden, wird diese kraft Rechnung geschuldet, berechtigt den Leistungsempfänger jedoch nicht zum Vorsteuerabzug.
                  </div>

                  <h2 className='text-2xl font-bold py-4'>
                    Rechnungen bei Reverse Charge
                  </h2>
                  <div className='pb-4'>
                    Beim Reverse Charge hat der leistende Unternehmer
                    <ul className="list-disc mx-6">
                      <li>die UID-Nummer des Leistungsempfängers anzugeben und</li>
                      <li>einen Vermerk anzuführen, dass die Steuerschuld auf den Leistungsempfänger übergeht</li>
                    </ul>
                    <br />
                    Sollte fälschlicherweise Umsatzsteuer ausgewiesen werden, wird diese kraft Rechnung geschuldet, berechtigt den Leistungsempfänger jedoch nicht zum Vorsteuerabzug.
                  </div>

                </div>
              </div>
            </TabPanel>
            <TabPanel header={
              <div className={activeIndex === 1 ? "text-4xl text-center p-4 w-[40vw] text-neutral-800 border-b-4 border-neutral-800" : "text-4xl text-center p-4 w-[40vw] text-gray-500 border-b-4 border-gray-400"}>DE</div>
            }>
              <div className='py-8 w-[80vw] sm:w-[60vw]'>
                <div className='py-4'>
                  {einleitung}
                </div>

                <h2 className='text-2xl font-bold py-4'>
                  Pflichtangaben auf einer Rechnung
                </h2>

                <div>
                  <div className='pb-4'>
                    Vor allem muss aus steuerrechtlicher Sicht darauf geachtet, dass Rechnungen alle Pflichtangaben beinhalten. Ansonsten kann es bei zu rechtlichen Problemen kommen. Folgende Pflichtangaben muss eine Rechnung nach {ustg14} enthalten:
                  </div>
                  <ul className='list-disc mx-6'>
                    <li>Genaue und korrekte Bezeichnung von Ihrem Unternehmen </li>
                    <li>Name und Anschrift von Ihrem Unternehmen </li>
                    <li>Ihre Steuernummer oder Umsatzsteuer-Identifikationsnummer </li>
                    <li>Name, Firma und genaue Anschrift des Rechnungsempfängers (Rechnungsadresse) </li>
                    <li>Das Ausstellungsdatum der Rechnung</li>
                    <li>Lieferdatum oder Leistungsdatum</li>
                    <li>Die Rechnungsnummer (fortlaufende Rechnungsnummer)</li>
                    <li>Genaue Angaben zu Art und Anzahl der gelieferten Waren oder erbrachten Dienstleistung</li>
                    <li>Genau aufgeschlüsselte Rechnungsbeträge</li>
                    <li>Gesamtpreis (Netto und Brutto sowie den Betrag der Umsatzsteuer)</li>
                    <li>Mögliche Rabatte und/oder Skonto</li>
                    <li>Das Zahlungsziel</li>
                  </ul>
                </div>

                <h2 className='text-2xl font-bold py-4'>
                  Rechnung schreiben nach Unternehmensart
                </h2>

                <h2 className='text-xl font-semibold py-4'>
                  Rechnung schreiben ohne Gewerbe
                </h2>
                <div className='pb-4'>
                  Rechnungen schreiben ohne ein Gewerbe zu besitzen ist grundsätzlich möglich. Welche Regeln hier zutreffen hängt davon ab, ob Sie als Freiberufler oder als Privatperson eine Rechnung schreiben wollen. Unabhängig davon muss die Rechnung aber die gleichen Pflichtangaben beinhalten wie eine Rechnung die von einem Gewerbetreibenden ausgestellt wird.
                </div>

                <h2 className='text-xl font-semibold py-4'>
                  Rechnung schreiben als Freiberufler
                </h2>
                <div className='pb-4'>
                  Wie jeder andere Unternehmer müssen auch Freiberufler Rechnungen schreiben. Es gibt keine Unterschiede beim Schreiben eier Rechnung als Freiberufler zum Schreiben eier Rechnung als anderer Selbstständiger. Jedoch muss darauf geachtet werden ob die Rechnung unter die Kleinunternehmerregelung fällt, dann muss eine Kleinunternehmer Rechnung geschrieben werden.
                </div>



                <h2 className='text-xl font-semibold py-4'>
                  Rechnung schreiben als Kleinunternehmer
                </h2>
                <div className='pb-4'>
                  Kleinunternehmer müssen beim Rechnung schreiben besondere Pflichtangaben berücksichtigen. Diese sind in {ustg19} definiert.
                  <br />
                  <br />

                  <h2><u>Wann ist man Kleinunternehmer?</u></h2>
                  <ul className='list-disc mx-6'>
                    <li>Umsätze im vergangenen Jahr überstiegen einen Betrag von 22.000 € nicht</li>
                    <li>Umsätze im folgenden Jahr übersteigen einen Betrag von 50.000 € voraussichtlich nicht</li>
                  </ul>
                  <br />

                  <h2><u>Was sind die Pflichtangaben auf einer Kleinunternehmer Rechnung?</u></h2>
                  <ul className='list-disc mx-6'>
                    <li>Ein Hinweis darauf, dass Sie aufgrund der Kleinunternehmerregelung nach {ustg19} von der Ausweisung der Umsatzsteuer befreit sind (Gängige Formulierung: „Gemäß §19 UStG wird keine Umsatzsteuer berechnet.“)</li>
                    <li>Name und Anschrift von Ihrem Unternehmen </li>
                    <li>Ihre Steuernummer (nicht gleich Umsatzsteuer-Identifikationsnummer!!!) </li>
                    <li>Name, Firma und genaue Anschrift des Rechnungsempfängers (Rechnungsadresse) </li>
                    <li>Das Ausstellungsdatum der Rechnung</li>
                    <li>Lieferdatum oder Leistungsdatum</li>
                    <li>Die Rechnungsnummer (fortlaufende Rechnungsnummer)</li>
                    <li>Genaue Angaben zu Art und Anzahl der gelieferten Waren oder erbrachten Dienstleistung</li>
                    <li>Genau aufgeschlüsselte Rechnungsbeträge</li>
                    <li>Gesamtpreis (Netto und Brutto sowie den Betrag der Umsatzsteuer)</li>
                    <li>Mögliche Rabatte und/oder Skonto</li>
                    <li>Das Zahlungsziel</li>
                  </ul>
                  <br />

                  <u>Spezialfall: Kleinbetragsrechnung (Gesamtbetrag {'<250'} Euro). Es enfallen folgende Pflichtangaben:</u>
                  <ul className='list-disc mx-6'>
                    <li>Ihre Steuernummer (nicht gleich Umsatzsteuer-Identifikationsnummer!!!) </li>
                    <li>Name, Firma und genaue Anschrift des Rechnungsempfängers (Rechnungsadresse) </li>
                    <li>Lieferdatum oder Leistungsdatum</li>
                    <li>Die Rechnungsnummer (fortlaufende Rechnungsnummer)</li>
                  </ul>

                </div>

                <h2 className='text-xl font-semibold py-4'>
                  Rechnung schreiben als Privatperson
                </h2>
                <div className='pb-4'>
                  Falls Sie privat etwas verkaufen, können Sie als Privatperson eine Rechnung schreiben, müssen es aber nicht, da kein Rechnungszwang besteht. Wichtig ist, dass auf dieser Rechnung <u>kein Interesse als Unternehmer besteht</u>, die Rechnung <u>keinen gewerblichen Charakter</u> aufweist und das die Rechnung auf keinen Fall eine <u>Umsatzsteuer</u> ausweist!
                </div>

              </div>
            </TabPanel>
          </TabView>
        </div>
      </div>
    </div>
  )
}
