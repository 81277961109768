import React, { Component } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Dropdown } from 'primereact/dropdown';
import { ConfirmDialog } from 'primereact/confirmdialog'; // To use <ConfirmDialog> tag
import { confirmDialog } from 'primereact/confirmdialog'; // To use confirmDialog method
import { InputTextarea } from 'primereact/inputtextarea';

import makeInvoice from "../generateInvoice"


import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";                  //core css
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';

interface IProps {
}

interface TaxRate {
    rate: number,
    rateTotal: number
}

interface Position {
    id: number;
    nummer: string;
    beschreibung: string;
    menge: number;
    einzelpreis: number;
    mwst: number;
    gesamtpreis: number;
}

interface IState {
    data: {
        [key: string]: string,
    };
    total: {
        net: number,
        taxRates: Array<TaxRate>,
        gross: number,
    }
    positionen: Array<Position>;
    rechnungsdatum: any;
    lieferzeitraum: any;
    img: any;
    rechnungsart: any;
    width: number
}

export default class Home extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        document.title = "Rechnung Erstellen";
        this.state = {
            data: {
                "firmaHeader": "Meine Firma - Meine Adresse",
                "rechnungAnHeader": "Rechnung an",
                "rechnungAn": "Max Mustermann\nStrasse 1\n0000 Ort\nLand",
                "versandAnHeader": "Versand an",
                "versandAn": "Max Mustermann\nStrasse 1\n0000 Ort\nLand",
                "RechnungsNummerText": "Rechnungs-Nr.:",
                "RechnungsNummerValue": "0001",
                "RechnungsDatumText": "Rechnungs Datum:",
                "LieferzeitraumText": "Lieferzeitraum:",
                "KundennummerText": "Ihre Kundennummer:",
                "KundennummerValue": "1",
                "FirmenAnschrift": "Firmenname\nStrasse Nr.\nPLZ Ort\nLand",
                "FirmaKontakt": "Email: \nTelNr.: \nFN-Nr.: \nSteuer-Nr.: \nFB-Gericht: ",
                "Zahlungsbedingungen": "Zahlungsbedingungen: Zahlbar innerhalb 10 Tagen\nDie gelieferte Ware bleibt bis zur vollständigen Bezahlung Eigentum der [Ihre Firma]",
                "Bankverbindung": "Bankverbindung"
            },
            rechnungsdatum: new Date(), // todo sync date in template
            lieferzeitraum: [new Date(), new Date()], // todo sync date in template
            positionen: [
                { id: 0, nummer: '1', beschreibung: "Beschreibung", menge: 1, einzelpreis: 10, mwst: 20, gesamtpreis: 10 }, // todo pos auto sync on delete
            ],
            total: {
                net: 0,
                taxRates: [],
                gross: 0,
            },
            img: null,
            rechnungsart: null,
            width: window.innerWidth
        };


        this.rechnungErstellen = this.rechnungErstellen.bind(this);
        this.downloadTemplate = this.downloadTemplate.bind(this);
        this.loadTemplate = this.loadTemplate.bind(this);
        this.onAddClick = this.onAddClick.bind(this);
        this.einzelpreisTemplate = this.einzelpreisTemplate.bind(this);
        this.gesamtpreisTemplate = this.gesamtpreisTemplate.bind(this);
        this.formatEuro = this.formatEuro.bind(this);
        this.getTotalNet = this.getTotalNet.bind(this);
        this.readImg = this.readImg.bind(this);
        this.rechnungErstellenConfirm = this.rechnungErstellenConfirm.bind(this);
    }

    checklistAT = <ul className='list-disc mx-6'>
        <li>Name und Anschrift des liefernden oder leistenden Unternehmers</li>
        <li>Name und Anschrift des Leistungsempfängers</li>
        <li>Menge und handelsübliche Bezeichnung der Gegenstände bzw. Art und Umfang der Leistungen</li>
        <li>Tag/Zeitraum der Lieferung oder sonstigen Leistung</li>
        <li>Entgelt für die Lieferung/sonstige Leistung und der anzuwendende Steuersatz bzw. bei Steuerbefreiung einen Hinweis auf diese</li>
        <li>den auf das Entgelt entfallenden Steuerbetrag</li>
        <li>Ausstellungsdatum (wenn dieses gleich ist mit dem Tag der Lieferung oder sonstigen Leistung, genügt der Vermerk “Rechnungsdatum ist gleich Liefer- bzw. Leistungsdatum“)</li>
        <li>Fortlaufende Nummer</li>
        <li>Umsatzsteueridentifikationsnummer (UID-Nummer) des Ausstellers der Rechnung</li>
        <li>UID-Nummer des Leistungsempfängers (auf Rechnungen mit einem Gesamtbetrag über EUR 10.000,- inkl. USt, weiters wenn die Steuerschuld auf den Leistungsempfänger übergeht - Reverse Charge sowie bei innergemeinschaftlichen Lieferungen).</li>
        <li>bei Anwendung der Differenzbesteuerung hat ein Hinweis auf diese zu erfolgen (z.B. Antiquitätenhandel)</li>
        <li>Wird die Rechnung in einer anderen Währung als Euro ausgestellt, ist der Steuerbetrag zusätzlich in Euro anzugeben</li>
    </ul>

    checklistKleinbetragAt = <div className='pb-4'>
        Bei Rechnungen deren Gesamtbetrag 400 EUR inkl. Umsatzsteuer nicht übersteigt, genügen folgende Angaben:

        <ul className='list-disc mx-6'>
            <li>Name und Anschrift des liefernden oder leistenden Unternehmers</li>
            <li>Menge und handelsübliche Bezeichnung der Gegenstände bzw. Art und Umfang der Leistungen</li>
            <li>Tag/Zeitraum der Lieferung oder sonstigen Leistung</li>
            <li>Entgelt und Steuerbetrag in einer Summe</li>
            <li>Steuersatz</li>
            <li>Ausstellungsdatum</li>
        </ul>
        <br />
        Die Vereinfachungsbestimmungen für Rechnungen bis zu 400 EUR gelten nicht für innergemeinschaftliche Lieferungen. Diese Rechnungen müssen jedenfalls einen Hinweis auf die Steuerbefreiung sowie die UID-Nummer des liefernden Unternehmers und des Abnehmers enthalten.
    </div>

    checklistDE = <ul className='list-disc mx-6'>
        <li>Genaue und korrekte Bezeichnung von Ihrem Unternehmen </li>
        <li>Name und Anschrift von Ihrem Unternehmen </li>
        <li>Ihre Steuernummer oder Umsatzsteuer-Identifikationsnummer </li>
        <li>Name, Firma und genaue Anschrift des Rechnungsempfängers (Rechnungsadresse) </li>
        <li>Das Ausstellungsdatum der Rechnung</li>
        <li>Lieferdatum oder Leistungsdatum</li>
        <li>Die Rechnungsnummer (fortlaufende Rechnungsnummer)</li>
        <li>Genaue Angaben zu Art und Anzahl der gelieferten Waren oder erbrachten Dienstleistung</li>
        <li>Genau aufgeschlüsselte Rechnungsbeträge</li>
        <li>Gesamtpreis (Netto und Brutto sowie den Betrag der Umsatzsteuer)</li>
        <li>Mögliche Rabatte und/oder Skonto</li>
        <li>Das Zahlungsziel</li>
    </ul>

    checklistKleinunternehmerDE = <ul className='list-disc mx-6'>
        <li>Ein Hinweis darauf, dass Sie aufgrund der Kleinunternehmerregelung nach Ustg§19 von der Ausweisung der Umsatzsteuer befreit sind (Gängige Formulierung: „Gemäß §19 UStG wird keine Umsatzsteuer berechnet.“)</li>
        <li>Name und Anschrift von Ihrem Unternehmen </li>
        <li>Ihre Steuernummer (nicht gleich Umsatzsteuer-Identifikationsnummer!!!) </li>
        <li>Name, Firma und genaue Anschrift des Rechnungsempfängers (Rechnungsadresse) </li>
        <li>Das Ausstellungsdatum der Rechnung</li>
        <li>Lieferdatum oder Leistungsdatum</li>
        <li>Die Rechnungsnummer (fortlaufende Rechnungsnummer)</li>
        <li>Genaue Angaben zu Art und Anzahl der gelieferten Waren oder erbrachten Dienstleistung</li>
        <li>Genau aufgeschlüsselte Rechnungsbeträge</li>
        <li>Gesamtpreis (Netto und Brutto sowie den Betrag der Umsatzsteuer)</li>
        <li>Mögliche Rabatte und/oder Skonto</li>
        <li>Das Zahlungsziel</li>
    </ul>

    checklistKleinbetragDE = <ul className='list-disc mx-6'>
        <li>Ihre Steuernummer (nicht gleich Umsatzsteuer-Identifikationsnummer!!!) </li>
        <li>Name, Firma und genaue Anschrift des Rechnungsempfängers (Rechnungsadresse) </li>
        <li>Lieferdatum oder Leistungsdatum</li>
        <li>Die Rechnungsnummer (fortlaufende Rechnungsnummer)</li>
    </ul>

    rechnungsarten = [
        {
            label: <h1 className='px-4 font-bold'>Österreich</h1>, code: 'AT',
            items: [
                { label: 'Gewerbe-AT', checklist: this.checklistAT },
                { label: 'Privatperson-AT', checklist: this.checklistAT },
                { label: 'Kleinbetragsrechnung-AT', checklist: this.checklistKleinbetragAt },
            ]
        },
        {
            label: <h1 className='px-4 font-bold'>Deutschland</h1>, code: 'DE',
            items: [
                { label: 'Gewerbe-DE', checklist: this.checklistDE },
                { label: 'Kleinunternehmer-DE', checklist: this.checklistKleinunternehmerDE },
                { label: 'Freiberufler-DE', checklist: this.checklistDE },
                { label: 'Privatperson-DE', checklist: this.checklistDE },
                { label: 'Kleinbetragsrechnung-DE', checklist: this.checklistKleinbetragDE },
            ]
        },
    ];

    componentDidMount(): void {
        this.updateTotals();
    }

    componentDidUpdate(previousProps: any, previousState: any) {

    }

    rechnungErstellen() {
        makeInvoice(this.state.data, this.state.positionen, this.state.total, this.state.rechnungsdatum, this.state.lieferzeitraum, this.state.img);
    }

    rechnungErstellenConfirm() {
        if (this.state.rechnungsart === null) {
            (document.getElementById("rechungsartDropdown") as HTMLElement).style.borderColor = "#ff0000";
            (document.getElementById("rechungsartDropdown") as HTMLElement).style.borderWidth = "2px";
            return
        }

        this.showOverlay(true);
        confirmDialog({
            onShow: () => { this.showOverlay(true) },
            onHide: () => { this.showOverlay(false) },
            className: "font-mono",
            header: 'Haben Sie folgende Pflichtfelder in Ihrer Rechnung beachtet?',
            message: <>
                <div className='w-[50vw]'>
                    {this.state.rechnungsart.checklist}
                </div>
                <br />
                <NavLink key="guide" to="/rechnungen-richtig-schreiben" target="_blank" className="text-sm underline hover:font-semibold font-mono">
                    Mehr Informationen
                </NavLink>
            </>,
            acceptLabel: "Ja, Rechnung erstellen!",
            rejectLabel: "Noch nicht",
            acceptClassName: "bg-neutral-800 px-4 py-2 text-white rounded-md",
            rejectClassName: "border border-neutral-800 px-4 py-2 rounded-md",
            accept: () => { this.rechnungErstellen(); this.showOverlay(false) },
            reject: () => { this.showOverlay(false) },
        });
    }

    showOverlay(state: boolean) {
        var overlay = (document.getElementById("overlay") as HTMLElement);
        if (state)
            overlay.style.display = 'block';
        else
            overlay.style.display = 'none';
    }

    updateTotals() {
        let totalNet = 0;
        let taxRates = [] as TaxRate[];
        let totalGross = 0;

        this.state.positionen.forEach(pos => {
            totalNet += pos.gesamtpreis;

            var foundIndex = taxRates.findIndex(rate => rate.rate === pos.mwst);

            if (foundIndex === -1) {
                taxRates.push({ rate: pos.mwst, rateTotal: (pos.gesamtpreis * (pos.mwst / 100)) });
            } else {
                taxRates[foundIndex].rateTotal += (pos.gesamtpreis * (pos.mwst / 100))
            }

            totalGross += pos.gesamtpreis * (1 + pos.mwst / 100)
        });

        this.setState({
            total: {
                net: totalNet,
                taxRates: taxRates,
                gross: totalGross
            }
        });
    }

    textEditor(options: any) {
        return <InputText value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    textAreaEditor(options: any) {
        return <InputTextarea rows={1} cols={30} value={options.value} className='mt-[0.4rem] py-[1px] px-2' onChange={(e) => options.editorCallback(e.target.value)} autoResize />;
    }

    numberEditor(options: any) {
        return <InputNumber value={options.value} onChange={(e) => options.editorCallback(e.value)} locale="de-DE"></InputNumber>
    }

    percentEditor(options: any) {
        return <InputNumber value={options.value} onChange={(e) => options.editorCallback(e.value)} suffix="%"></InputNumber>
    }

    currencyEditor(options: any) {
        return <InputNumber value={options.value} onChange={(e) => options.editorCallback(e.value)} mode="currency" currency="EUR" locale="de-DE"></InputNumber>
    }

    onRowEditComplete = (e: any) => {
        let positionenNeu = [...this.state.positionen];
        let { newData, index } = e;

        newData.gesamtpreis = newData.menge * newData.einzelpreis;
        positionenNeu[index] = newData;

        this.setState(
            {
                positionen: positionenNeu
            },
            this.updateTotals
        );
    }

    onDeleteClick(idx: number) {
        let positionenNeu = [...this.state.positionen];

        if (idx !== -1 && positionenNeu.length !== 1) {
            positionenNeu.splice(idx, 1)
        }

        this.setState(
            {
                positionen: positionenNeu
            },
            this.updateTotals
        );
    }

    onAddClick() {
        let positionenNeu = [...this.state.positionen];
        let len = positionenNeu.length;

        positionenNeu.push(
            { id: len, nummer: (len + 1).toString(), beschreibung: "Beschreibung", menge: 1, einzelpreis: 10, mwst: 20, gesamtpreis: 10 }
        )

        this.setState(
            {
                positionen: positionenNeu
            },
            this.updateTotals
        );
    }

    updateData(e: any) { // todo reindex id on update
        let dataNew = { ...this.state.data }
        dataNew[e.target.id] = e.target.value

        this.setState({
            data: dataNew
        })
    }

    getTotalNet() {
        return this.formatEuro(this.state.total.net);
    }

    getTotalGross() {
        return this.formatEuro(this.state.total.gross);
    }

    downloadTemplate() {
        var encodedDataStr = window.btoa(JSON.stringify(this.state.data));
        var encodedPosStr = ':' + window.btoa(JSON.stringify(this.state.positionen));
        var encodedImgStr = '';

        if (this.state.img) {
            encodedImgStr = ':' + this.state.img.replace('data:', '').replace(/^.+,/, '');
        }

        var encoded = `${encodedDataStr}${encodedPosStr}${encodedImgStr}`;

        const blob = new Blob([encoded], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = "RechnungVorlage.rechnung";
        link.href = url;
        link.click();
    }

    loadTemplate(e: any) {
        if (e.target.files) {
            const reader = new FileReader()

            reader.onload = (file: any) => {
                const text = (file.target.result)
                const encodedArray = text.split(':');

                var actualData = JSON.parse(window.atob(encodedArray[0]));
                var actualPos = JSON.parse(window.atob(encodedArray[1]));
                var actualImg = null;

                if (encodedArray.length > 2) {
                    actualImg = `data:<type>;base64,${encodedArray[2]}`;
                }

                this.setState({
                    data: actualData,
                    positionen: actualPos,
                    img: actualImg
                },
                    this.updateTotals
                );

            };

            reader.readAsText(e.target.files[0])
        }

    }

    readImg(e: any) {
        if (e.target.files) {
            let file = e.target.files[0];

            const reader = new FileReader();

            reader.onloadend = () => {
                console.log(reader.result);
                this.setState({
                    img: reader.result
                })
            };

            reader.readAsDataURL(file);
        }
    }


    percentTemplate(rowData: any) {
        return `${rowData.mwst}%`
    }

    formatEuro(num: number) {
        var formatter = new Intl.NumberFormat("de-DE", {
            style: "currency",
            currency: "EUR"
        })
        return formatter.format(num);
    }

    einzelpreisTemplate(rowData: any) {
        return this.formatEuro(rowData.einzelpreis);
    }

    gesamtpreisTemplate(rowData: any) {
        return this.formatEuro(rowData.gesamtpreis);
    }

    footerGroup() {
        return (
            <ColumnGroup>
                <Row>
                    <Column />
                    <Column footer="Gesamtpreis netto:" colSpan={4} className="text-start px-2 font-mono" />
                    <Column footer={this.getTotalNet()} className="text-end font-mono px-2" />
                </Row>

                {this.state.total.taxRates.map(rate => {
                    return (
                        <Row key={rate.rate}>
                            <Column />
                            <Column footer={`zzgl. ${rate.rate}% MwSt:`} colSpan={4} className="text-start px-2 font-mono" />
                            <Column footer={this.formatEuro(rate.rateTotal)} className="text-end font-mono px-2" />
                        </Row>
                    )
                })}

                <Row>
                    <Column />
                    <Column footer="Gesamtpreis brutto:" colSpan={4} className="text-start px-2 font-bold font-mono" />
                    <Column footer={this.getTotalGross()} className="text-end font-bold font-mono px-2" />
                </Row>
            </ColumnGroup>
        );
    }

    seoInfoHeader() {
        return (
            <div>
                <Helmet>
                    <title data-react-helmet="true">Online Rechnung Erstellen! | Kostenlos Sofort Download!</title>
                    <meta
                        name="description"
                        content="Kostenlos online Rechnungen erstellen mit unserem Rechnungsgenerator. Rechnungen für Kleinunternehmen, Firmen sowie Freiberufler und Privatpersonen."
                        data-react-helmet="true"
                    />

                    <meta
                        name="keywords"
                        content="Rechnung erstellen, Rechnung Vorlage, Rechnung Kleingewerbe, Rechnung kostenlos, Rechnung, Rechnung schreiben, Rechnungsgenerator, Buchhaltungssoftware"
                        data-react-helmet="true"
                    />
                    <meta name="robots" content="noodp,noydir" />
                </Helmet>
                <div className="bg-white mt-6 shadow-md">
                    <div className='mx-auto w-[90vw] sm:w-[74rem] h-max font-mono'>
                        <div className='sm:w-[60rem]'>
                            <h1 className='font-bold text-2xl py-2'>Kostenlos Rechnung erstellen mit unserer Vorlage!</h1>
                            <h2 className='font-semibold text-lg py-1'>Schnell und einfach in wenigen Clicks</h2>
                            <p className='text-md pb-4'>Schritt für Schritt zur eigenen Rechnung, mit unserem Rechnungsgenerator kommen Sie so einfach wie noch nie
                                zu Ihrer perfekten Rechnung. Täglich von tausenden verwendet bieten wir Ihnen das beste 100% kostenlose Tool
                                nur hier an. Sie haben auch die Möglichkeit eine <NavLink to={'/hilfe'}><u >Vorlage</u> </NavLink> zu erstellen, um das Erstellen mehrerer Rechnungen schneller
                                und einfacher zu machen.
                            </p>
                            <h3 className='font-semibold text-lg py-1'>Wenn, dann richtig!</h3>
                            <p className='text-md pb-4'>Um Probleme mit Ihrer Rechnung zu vermeiden, bieten wir Ihnen nicht 
                                nur einen Generator an, sondern auch eine umfassenden Überblick der <NavLink to={'/rechnungen-richtig-schreiben'}><u >Richtlinien und gesetzlichen Vorgaben</u> </NavLink>
                                die Sie auf Ihrer Rechnung beachten müssen. Wichtig ist, dass Sie die Pflichtangaben passend zu Ihrem Unternehmen wählen.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {

        const border = "border rounded-sm border-gray-200 truncate";

        const { width } = this.state;
        const isMobile = width <= 1250;

        if (isMobile) {
            return (
                <div>
                    {this.seoInfoHeader()}
                    <div className="my-8 mx-2 bg-white">
                        <div className="grid grid-flow-row auto-rows-max">

                            {/* Head */}
                            <div className='relative h-[42rem]'>

                                <div
                                    className="absolute grid h-32 w-32 bg-gray-100 top-4 right-4 rounded-md place-content-center font-mono bg-cover bg-center"
                                    style={{ backgroundImage: `url(${this.state.img}` }}
                                >
                                    <label id="imgLabel" htmlFor="upload-photo" className={this.state.img == null ? "flex h-32 w-32 justify-center items-center cursor-pointer hover:underline" : "flex h-32 w-32 justify-center items-center cursor-pointer hover:underline text-transparent"}>+ Logo</label>
                                    <input id="upload-photo" type="file" name="photo" accept="image/*" onChange={this.readImg} className="h-32 w-32" />
                                </div>

                                <input
                                    id="firmaHeader"
                                    className={`input absolute grid h-5 w-48 top-16 left-4 place-content-center text-xs font-mono cursor-pointer px-1 ${border}`}
                                    value={this.state.data["firmaHeader"]}
                                    onChange={(e) => this.updateData(e)}
                                >
                                </input>

                                <div className="absolute grid grid-flow-row auto-rows-max top-40 left-4 space-y-6">
                                    <div>
                                        <input
                                            id="rechnungAnHeader"
                                            className="grid h-4 w-fit place-content-center text-xs font-mono cursor-pointer pl-2 mb-2 text-gray-500 "
                                            value={this.state.data["rechnungAnHeader"]}
                                            onChange={(e) => this.updateData(e)}
                                        />
                                        <textarea
                                            style={{ resize: 'none' }}
                                            id="rechnungAn"
                                            className={`grid h-20 w-72 place-content-center text-sm font-mono cursor-pointer pl-2 whitespace-pre-wrap ${border}`}
                                            value={this.state.data["rechnungAn"]}
                                            onChange={(e) => this.updateData(e)}
                                        />
                                    </div>

                                    <div>
                                        <input
                                            id="versandAnHeader"
                                            className="grid h-4 w-fit place-content-center text-xs font-mono cursor-pointer pl-2 mb-2 text-gray-500"
                                            value={this.state.data["versandAnHeader"]}
                                            onChange={(e) => this.updateData(e)}
                                        />
                                        <textarea
                                            style={{ resize: 'none' }}
                                            id="versandAn"
                                            className={`grid h-20 w-72 place-content-center text-sm font-mono cursor-pointer pl-2 whitespace-pre-wrap ${border}`}
                                            value={this.state.data["versandAn"]}
                                            onChange={(e) => this.updateData(e)}
                                        />
                                    </div>

                                    <div className='flex'>
                                        <input
                                            id="RechnungsNummerText"
                                            className="h-6 w-36 place-content-center text-end text-md font-mono font-semibold cursor-pointer pr-2 mb-1"
                                            value={this.state.data["RechnungsNummerText"]}
                                            onChange={(e) => this.updateData(e)}
                                        />
                                        <input
                                            id="RechnungsNummerValue"
                                            className={`grid h-6 w-48 place-content-center text-end text-md font-mono font-semibold cursor-pointer pl-2 mb-1 ${border}`}
                                            value={this.state.data["RechnungsNummerValue"]}
                                            onChange={(e) => this.updateData(e)}
                                        />
                                    </div>

                                    <div className='flex'>
                                        <input
                                            id="RechnungsDatumText"
                                            className="grid h-5 w-36 place-content-center text-end text-sm font-mono cursor-pointer pr-2 mb-1"
                                            value={this.state.data["RechnungsDatumText"]}
                                            onChange={(e) => this.updateData(e)}
                                        />
                                        <Calendar
                                            onChange={(e) => this.setState({ rechnungsdatum: e.value })}
                                            value={this.state.rechnungsdatum}
                                            className="w-full h-5 text-sm"
                                            dateFormat="dd/mm/yy"
                                            inputClassName="text-end" />
                                    </div>

                                    <div className='flex'>
                                        <input
                                            id="LieferzeitraumText"
                                            className="grid h-5 w-36 place-content-center text-end text-sm font-mono cursor-pointer pr-2 mb-1"
                                            value={this.state.data["LieferzeitraumText"]}
                                            onChange={(e) => this.updateData(e)}
                                        />
                                        <Calendar
                                            value={this.state.lieferzeitraum}
                                            onChange={(e) => this.setState({ lieferzeitraum: e.value })}
                                            selectionMode='range'
                                            className="w-full h-5 text-sm"
                                            dateFormat="dd/mm/yy"
                                            inputClassName="text-end"
                                        />
                                    </div>

                                    <div className='flex'>
                                        <input
                                            id="KundennummerText"
                                            className="grid h-5 w-36 place-content-center text-end text-sm font-mono cursor-pointer pr-2 mb-1"
                                            value={this.state.data["KundennummerText"]}
                                            onChange={(e) => this.updateData(e)}
                                        />
                                        <input
                                            id="KundennummerValue"
                                            className={`grid h-5 w-48 place-content-center text-end text-sm font-mono cursor-pointer pl-2 mb-1 ${border}`}
                                            value={this.state.data["KundennummerValue"]}
                                            onChange={(e) => this.updateData(e)}
                                        />
                                    </div>

                                </div>

                                <div className="absolute flex top-[39rem] left-4">
                                    <input
                                        id="RechnungsNummerText"
                                        className="h-8 w-44 place-content-center text-start text-xl font-mono font-semibold cursor-pointer pl-2 mb-1 mr-2"
                                        value={this.state.data["RechnungsNummerText"]}
                                        onChange={(e) => this.updateData(e)}
                                    />
                                    <input
                                        id="RechnungsNummerValue"
                                        className="h-8 w-36 place-content-center text-start text-xl font-mono font-semibold cursor-pointer pl-2 mb-1"
                                        value={this.state.data["RechnungsNummerValue"]}
                                        onChange={(e) => this.updateData(e)}
                                    />
                                </div>


                            </div>

                            {/* Items */}
                            <div className='w-[95vw]'>
                                <div className="h-max pb-12 mx-4">
                                    <div className='w-full'>
                                        <div className="card">
                                            <DataTable
                                                value={this.state.positionen}
                                                footerColumnGroup={this.footerGroup()}
                                                onRowEditComplete={this.onRowEditComplete}
                                                size="small" editMode="row" dataKey="id" responsiveLayout="scroll" stripedRows
                                            >
                                                <Column
                                                    field="nummer"
                                                    header="Pos"
                                                    className='text-center font-mono cursor-default w-8'
                                                    editor={(options) => this.numberEditor(options)}
                                                />
                                                <Column
                                                    field="beschreibung"
                                                    header="Beschreibung"
                                                    bodyClassName='text-start font-mono cursor-default w-[28rem] break-all p-2'
                                                    editor={(options) => this.textEditor(options)}
                                                />
                                                <Column
                                                    field="menge"
                                                    header="Menge"
                                                    className='text-center font-mono cursor-default w-8'
                                                    editor={(options) => this.numberEditor(options)}
                                                />
                                                <Column
                                                    field="einzelpreis"
                                                    header="Einzelpreis"
                                                    body={this.einzelpreisTemplate}
                                                    className='text-center px-2 font-mono cursor-default'
                                                    editor={(options) => this.currencyEditor(options)}
                                                />
                                                <Column
                                                    field="mwst"
                                                    header="MwSt"
                                                    className='text-center px-2 font-mono cursor-default'
                                                    body={this.percentTemplate}
                                                    editor={(options) => this.percentEditor(options)}
                                                />
                                                <Column
                                                    field="gesamtpreis"
                                                    header="Gesamtpreis"
                                                    body={this.gesamtpreisTemplate}
                                                    className='text-end px-2 font-mono cursor-default'
                                                />
                                                <Column rowEditor
                                                    className='min-w-8 max-w-8'
                                                    headerClassName='bg-neutral-800 text-gray-100 font-mono w-16'
                                                />
                                                <Column body={(data, props) => <div onClick={() => this.onDeleteClick(props.rowIndex)}><i className='pi pi-trash' /></div>}
                                                    className='w-8 cursor-pointer text-red-500 font-bold pointer-none select-none'
                                                >
                                                </Column>
                                            </DataTable>
                                        </div>

                                        <button
                                            className='flex justify-center items-center bg-neutral-800 w-8 h-8 rounded-full text-white m-4'
                                            onClick={this.onAddClick}
                                        >
                                            <i className='pi pi-plus' />
                                        </button>

                                    </div>
                                </div>
                            </div>

                            {/* Footer */}
                            <div>
                                <div className="relative h-[33rem] w-full">
                                    <div className="absolute top-0 left-4">
                                        <div className=''>
                                            <textarea
                                                style={{ resize: 'none' }}
                                                id="Zahlungsbedingungen"
                                                className={`grid h-28 w-80 place-content-center text-sm font-mono cursor-pointer pt-1 pl-2 whitespace-pre-wrap ${border}`}
                                                value={this.state.data["Zahlungsbedingungen"]}
                                                onChange={(e) => this.updateData(e)}
                                            />
                                        </div>
                                    </div>

                                    <div className="absolute top-32 left-4">
                                        <div>
                                            <textarea
                                                style={{ resize: 'none' }}
                                                id="Bankverbindung"
                                                className={`grid h-28 w-80 place-content-center text-sm font-mono cursor-pointer pt-1 pl-2 whitespace-pre-wrap ${border}`}
                                                value={this.state.data["Bankverbindung"]}
                                                onChange={(e) => this.updateData(e)}
                                            />
                                        </div>
                                    </div>

                                    <div className="absolute flex space-x-4 top-64 left-4">
                                        <div>
                                            <textarea
                                                style={{ resize: 'none' }}
                                                id="FirmenAnschrift"
                                                className={`grid h-28 w-80 place-content-center text-sm font-mono cursor-pointer pt-1 pl-2 whitespace-pre-wrap ${border}`}
                                                value={this.state.data["FirmenAnschrift"]}
                                                onChange={(e) => this.updateData(e)}
                                            />
                                        </div>
                                    </div>

                                    <div className="absolute flex space-x-4 top-96 left-4">
                                        <div>
                                            <textarea
                                                style={{ resize: 'none' }}
                                                id="FirmaKontakt"
                                                className={`grid h-28 w-80 place-content-center text-sm font-mono cursor-pointer pt-1 pl-2 whitespace-pre-wrap ${border}`}
                                                value={this.state.data["FirmaKontakt"]}
                                                onChange={(e) => this.updateData(e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='fixed bottom-0 w-full bg-neutral-300 h-18 grid grid-cols-10 space-x-4'>
                        <div className="col-span-6">
                            <Dropdown id="rechungsartDropdown" className='m-2 w-full pl-2 py-4 font-mono' panelClassName="font-mono" value={this.state.rechnungsart} options={this.rechnungsarten} onChange={(e) => { (document.getElementById("rechungsartDropdown") as HTMLElement).style.borderColor = "#4BB543";; this.setState({ rechnungsart: e.value }) }} optionLabel="label" optionGroupLabel="label" optionGroupChildren="items" placeholder="Rechnungsart" />
                        </div>
                        <div className="col-span-4">
                            <Button label="Rechnung Erstellen" onClick={this.rechnungErstellen} className="absolute right-0  p-button-raised w-[30vw] h-14 p-button-rounded text-white m-2 bg-neutral-800 px-4 rounded-md font-mono" />
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    {this.seoInfoHeader()}
                    <div className="flex h-full">
                        <div id='overlay' className='overlay' />
                        <ConfirmDialog />
                        <div className="grid grid-flow-col auto-cols-max py-8 sm:mx-auto">
                            <div className='grid grid-flow-row auto-rows-max w-[60rem] bg-white border border-1 rounded-md border-neutral-800'>
                                {/* Invoice Header */}
                                <div className="relative h-[30rem] w-full">
                                    <div
                                        className="absolute grid h-32 w-32 bg-gray-100 top-8 rounded-md right-16 place-content-center font-mono  bg-cover bg-center"
                                        style={{ backgroundImage: `url(${this.state.img}` }}
                                    >
                                        <label id="imgLabel" htmlFor="upload-photo" className={this.state.img == null ? "flex h-32 w-32 justify-center items-center cursor-pointer hover:underline" : "flex h-32 w-32 justify-center items-center cursor-pointer hover:underline text-transparent"}>+ Logo</label>
                                        <input id="upload-photo" type="file" name="photo" accept="image/*" onChange={this.readImg} />
                                    </div>
                                    <input
                                        id="firmaHeader"
                                        className={`input absolute grid h-5 w-[30rem] top-48 left-20 place-content-center text-xs font-mono cursor-pointer px-1 ${border}`}
                                        value={this.state.data["firmaHeader"]}
                                        onChange={(e) => this.updateData(e)}
                                    >
                                    </input>
                                    <div className="absolute flex space-x-4 top-64 left-20">
                                        <div>
                                            <input
                                                id="rechnungAnHeader"
                                                className="grid h-4 w-fit place-content-center text-xs font-mono cursor-pointer pl-2 mb-2 text-gray-500 "
                                                value={this.state.data["rechnungAnHeader"]}
                                                onChange={(e) => this.updateData(e)}
                                            />
                                            <textarea
                                                style={{ resize: 'none' }}
                                                id="rechnungAn"
                                                className={`grid h-20 w-fit place-content-center text-sm font-mono cursor-pointer pl-2 whitespace-pre-wrap ${border}`}
                                                value={this.state.data["rechnungAn"]}
                                                onChange={(e) => this.updateData(e)}
                                            />
                                        </div>
                                        <div>
                                            <input
                                                id="versandAnHeader"
                                                className="grid h-4 w-fit place-content-center text-xs font-mono cursor-pointer pl-2 mb-2 text-gray-500"
                                                value={this.state.data["versandAnHeader"]}
                                                onChange={(e) => this.updateData(e)}
                                            />
                                            <textarea
                                                style={{ resize: 'none' }}
                                                id="versandAn"
                                                className={`grid h-20 w-fit place-content-center text-sm font-mono cursor-pointer pl-2 whitespace-pre-wrap ${border}`}
                                                value={this.state.data["versandAn"]}
                                                onChange={(e) => this.updateData(e)}
                                            />
                                        </div>
                                        <div>
                                            <div className='flex space-x-2 pb-4'>
                                                <input
                                                    id="RechnungsNummerText"
                                                    className="h-6 w-44 place-content-center text-end text-md font-mono font-semibold cursor-pointer pl-2 mb-1"
                                                    value={this.state.data["RechnungsNummerText"]}
                                                    onChange={(e) => this.updateData(e)}
                                                />
                                                <input
                                                    id="RechnungsNummerValue"
                                                    className={`grid h-6 w-56 place-content-center text-end text-md font-mono font-semibold cursor-pointer pl-2 mb-1 ${border}`}
                                                    value={this.state.data["RechnungsNummerValue"]}
                                                    onChange={(e) => this.updateData(e)}
                                                />
                                            </div>
                                            <div className='flex space-x-2'>
                                                <input
                                                    id="RechnungsDatumText"
                                                    className="grid h-5 w-fit place-content-center text-end text-sm font-mono cursor-pointer pl-2 mb-1"
                                                    value={this.state.data["RechnungsDatumText"]}
                                                    onChange={(e) => this.updateData(e)}
                                                />
                                                <Calendar
                                                    onChange={(e) => this.setState({ rechnungsdatum: e.value })}
                                                    value={this.state.rechnungsdatum}
                                                    className="w-full h-5 text-sm"
                                                    dateFormat="dd/mm/yy"
                                                    inputClassName="text-end" />
                                            </div>
                                            <div className='flex space-x-2'>
                                                <input
                                                    id="LieferzeitraumText"
                                                    className="grid h-5 w-fit place-content-center text-end text-sm font-mono cursor-pointer pl-2 mb-1"
                                                    value={this.state.data["LieferzeitraumText"]}
                                                    onChange={(e) => this.updateData(e)}
                                                />
                                                <Calendar
                                                    value={this.state.lieferzeitraum}
                                                    onChange={(e) => this.setState({ lieferzeitraum: e.value })}
                                                    selectionMode='range'
                                                    className="w-full h-5 text-sm"
                                                    dateFormat="dd/mm/yy"
                                                    inputClassName="text-end"
                                                />
                                            </div>
                                            <div className='flex space-x-2'>
                                                <input
                                                    id="KundennummerText"
                                                    className="grid h-5 w-fit place-content-center text-end text-sm font-mono cursor-pointer pl-2 mb-1"
                                                    value={this.state.data["KundennummerText"]}
                                                    onChange={(e) => this.updateData(e)}
                                                />
                                                <input
                                                    id="KundennummerValue"
                                                    className={`grid h-5 w-56 place-content-center text-end text-sm font-mono cursor-pointer pl-2 mb-1 ${border}`}
                                                    value={this.state.data["KundennummerValue"]}
                                                    onChange={(e) => this.updateData(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="absolute top-[26rem] left-20">
                                        <input
                                            id="RechnungsNummerText"
                                            className="h-8 w-44 place-content-center text-start text-xl font-mono font-semibold cursor-pointer pl-2 mb-1 mr-2"
                                            value={this.state.data["RechnungsNummerText"]}
                                            onChange={(e) => this.updateData(e)}
                                        />
                                        <input
                                            id="RechnungsNummerValue"
                                            className="h-8 w-44 place-content-center text-start text-xl font-mono font-semibold cursor-pointer pl-2 mb-1"
                                            value={this.state.data["RechnungsNummerValue"]}
                                            onChange={(e) => this.updateData(e)}
                                        />
                                    </div>
                                </div>
                                {/* Invoice Items */}
                                <div className=" h-max w-full pb-12">
                                    <div className='ml-24 w-10/12'>
                                        <div className="card">
                                            <DataTable
                                                value={this.state.positionen}
                                                footerColumnGroup={this.footerGroup()}
                                                onRowEditComplete={this.onRowEditComplete}
                                                size="small" editMode="row" dataKey="id" responsiveLayout="scroll" stripedRows
                                            >
                                                <Column
                                                    field="nummer"
                                                    header="Pos"
                                                    className='text-center font-mono cursor-default w-8'
                                                    editor={(options) => this.numberEditor(options)}
                                                />
                                                <Column
                                                    field="beschreibung"
                                                    header="Beschreibung"
                                                    className='text-start font-mono cursor-default w-[28rem] break-all'
                                                    editor={(options) => this.textAreaEditor(options)}
                                                />
                                                <Column
                                                    field="menge"
                                                    header="Menge"
                                                    className='text-center font-mono cursor-default w-8'
                                                    editor={(options) => this.numberEditor(options)}
                                                />
                                                <Column
                                                    field="einzelpreis"
                                                    header="Einzelpreis"
                                                    body={this.einzelpreisTemplate}
                                                    className='text-center px-2 font-mono cursor-default'
                                                    editor={(options) => this.currencyEditor(options)}
                                                />
                                                <Column
                                                    field="mwst"
                                                    header="MwSt"
                                                    className='text-center px-2 font-mono cursor-default'
                                                    body={this.percentTemplate}
                                                    editor={(options) => this.percentEditor(options)}
                                                />
                                                <Column
                                                    field="gesamtpreis"
                                                    header="Gesamtpreis"
                                                    body={this.gesamtpreisTemplate}
                                                    className='text-end px-2 font-mono cursor-default'
                                                />
                                                <Column rowEditor
                                                    className='min-w-8 max-w-8'
                                                    headerClassName='bg-neutral-800 text-gray-100 font-mono w-16'
                                                />
                                                <Column body={(data, props) => <div onClick={() => this.onDeleteClick(props.rowIndex)}><i className='pi pi-trash' /></div>}
                                                    className='w-8 cursor-pointer text-red-500 font-bold pointer-none select-none'
                                                >
                                                </Column>
                                            </DataTable>
                                        </div>
                                        <button
                                            className='flex justify-center items-center bg-neutral-800 w-8 h-8 rounded-full text-white m-4'
                                            onClick={this.onAddClick}
                                        >
                                            <i className='pi pi-plus' />
                                        </button>
                                    </div>
                                </div>
                                {/* Payment */}
                                <div className="h-64 w-full">
                                    <div className="relative h-48 w-full">
                                        <div className="absolute space-x-4 top-0 left-20">
                                            <div className=''>
                                                <textarea
                                                    style={{ resize: 'none' }}
                                                    id="Zahlungsbedingungen"
                                                    className={`grid h-12 w-[50rem] place-content-center text-sm font-mono cursor-pointer pt-1 pl-2 whitespace-pre-wrap ${border}`}
                                                    value={this.state.data["Zahlungsbedingungen"]}
                                                    onChange={(e) => this.updateData(e)}
                                                />
                                            </div>
                                        </div>
                                        <div className="absolute space-x-4 top-16 left-20">
                                            <div>
                                                <textarea
                                                    style={{ resize: 'none' }}
                                                    id="Bankverbindung"
                                                    className={`grid h-28 w-96 place-content-center text-sm font-mono cursor-pointer pt-1 pl-2 whitespace-pre-wrap ${border}`}
                                                    value={this.state.data["Bankverbindung"]}
                                                    onChange={(e) => this.updateData(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Invoice Footer */}
                                <div className="h-36 w-full">
                                    <div className="relative h-36 w-full">
                                        <div className="absolute flex space-x-4 top-0 left-20">
                                            <div>
                                                <textarea
                                                    style={{ resize: 'none' }}
                                                    id="FirmenAnschrift"
                                                    className={`grid h-28 w-fit place-content-center text-sm font-mono cursor-pointer pt-1 pl-2 whitespace-pre-wrap ${border}`}
                                                    value={this.state.data["FirmenAnschrift"]}
                                                    onChange={(e) => this.updateData(e)}
                                                />
                                            </div>
                                            <div>
                                                <textarea
                                                    style={{ resize: 'none' }}
                                                    id="FirmaKontakt"
                                                    className={`grid h-28 w-96 place-content-center text-sm font-mono cursor-pointer pt-1 pl-2 whitespace-pre-wrap ${border}`}
                                                    value={this.state.data["FirmaKontakt"]}
                                                    onChange={(e) => this.updateData(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='grid grid-flow-row auto-rows-max'>
                                <Button label="Rechnung Erstellen" onClick={this.rechnungErstellenConfirm} className="p-button-raised p-button-rounded text-white m-4 bg-neutral-800 p-4 rounded-md font-mono" />
                                <Button label="Vorlage Speichern" onClick={this.downloadTemplate} className="p-button-raised p-button-rounded m-4 border-neutral-800 border-2 p-4 rounded-md font-mono" />
                                <Button label="Vorlage Hochladen" onClick={() => (document.getElementById('upload-template') as HTMLElement).click()} className="p-button-raised p-button-rounded m-4 border-neutral-800 border-2 p-4 rounded-md font-mono" />
                                <input id="upload-template" type="file" name="photo" accept=".rechnung" onChange={this.loadTemplate} hidden />
                                <Dropdown id="rechungsartDropdown" className='m-4 pl-2 py-4 font-mono w-56' panelClassName="font-mono" value={this.state.rechnungsart} options={this.rechnungsarten} onChange={(e) => { (document.getElementById("rechungsartDropdown") as HTMLElement).style.borderColor = "#4BB543";; this.setState({ rechnungsart: e.value }) }} optionLabel="label" optionGroupLabel="label" optionGroupChildren="items" placeholder="Rechnungsart" />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}
