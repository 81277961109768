import { jsPDF } from "jspdf";
// import logo from "./susLogo.png";

const footer = (page, data) => {
  var footerStart = 265;
  page.setFontSize(8);
  page.setFont(undefined, 'normal');

  page.setTextColor('#808080')
  page.text(data["FirmenAnschrift"], 20, footerStart);
  page.text(data["FirmaKontakt"], 70, footerStart);

  page.setTextColor('#000000')
  page.text("Seite: " + page.page, 190, footerStart + 20, 'right'); //print number bottom right
  page.page++;
};

const tableHeader = (page, positions, latestPosition) => {
  page.setFillColor('#262626');
  page.rect(22, latestPosition, 168, 8, 'F')

  page.setFontSize(10.5);
  page.setFont(undefined, 'bold');
  page.setTextColor('#FFFFFF');
  page.text("Pos", 28, latestPosition + 5.3, 'center');
  page.text("Beschreibung", 35, latestPosition + 5.3);
  page.text("Menge", 119.5, latestPosition + 5.3, 'center');
  page.text("Einzelpreis", 138.5, latestPosition + 5.3, 'center');
  page.text("MwSt", 156.5, latestPosition + 5.3, 'center');
  page.text("Gesamtpreis", 187, latestPosition + 5.3, 'right');
  page.setTextColor('#000000');
  page.setFont(undefined, 'normal');
};

const createTableRow = (page, nummer, beschreibung, menge, einzelpreis, mwst, gesamtpreis, latestPosition, height, gray, bold = false) => {
  if (gray) {
    page.setFillColor('#DEDEDE');
    page.rect(22, latestPosition, 168, height, 'F')
  }

  if (bold) {
    page.setFont(undefined, 'bold');
  }

  page.setFontSize(9);
  page.text(nummer, 28, latestPosition + 5.3, 'center');
  page.text(beschreibung, 35, latestPosition + 5.3);
  page.text(menge, 119.5, latestPosition + 5.3, 'center');
  page.text(einzelpreis, 138.5, latestPosition + 5.3, 'center');
  page.text(mwst, 156.5, latestPosition + 5.3, 'center');
  page.text(gesamtpreis, 187, latestPosition + 5.3, 'right');
  page.setFont(undefined, 'normal');
}

function formatEuro(num) {
  var formatter = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR"
  })
  return formatter.format(num);
}

jsPDF.API.generateInvoice = function (data, logo, positions, total) {
  this.setFontSize(10);
  this.text(data["firmaHeader"], 20, 65);

  if (logo !== null) {
    this.addImage(logo, "", 155, 20, 35, 35);
  }

  this.setFontSize(7);
  this.text(data["rechnungAnHeader"], 20, 80);
  this.setFontSize(9);
  this.text(data["rechnungAn"], 20, 84);
  this.setFontSize(7);
  this.text(data["versandAnHeader"], 65, 80);
  this.setFontSize(9);
  this.text(data["versandAn"], 65, 84);

  this.setFontSize(9);
  this.setFont(undefined, 'bold');
  this.text(data["RechnungsNummerText"], 150, 80, 'right');
  this.text(data["RechnungsNummerValue"], 190, 80, 'right');

  this.setFont(undefined, 'normal');
  this.text(data["RechnungsDatumText"], 150, 84, 'right');
  this.text(data["RechnungsDatumValue"], 190, 84, 'right');

  this.text(data["LieferzeitraumText"], 150, 88, 'right');
  this.text(data["LieferzeitraumValue"], 190, 88, 'right');

  this.text(data["KundennummerText"], 150, 92, 'right');
  this.text(data["KundennummerValue"], 190, 92, 'right');

  this.setFontSize(13);
  this.setFont(undefined, 'bold');
  this.text(data["RechnungsNummerText"], 20, 115);
  this.text(data["RechnungsNummerValue"], 77.3, 115, 'right');

  var latestPosition = 120;
  tableHeader(this, positions, latestPosition);

  latestPosition = 128;
  let counter = 1;

  let maxTableY = 250;
  let spaceAfterTable = 20;
  let ZahlungsbedingungenHeight = 12;
  let invoiceTotalSpace = + 16 + 8 * total["taxRates"].length + spaceAfterTable + ZahlungsbedingungenHeight;

  let maxBeschreibungChars = 50;

  positions.forEach(pos => {

    let newLineCnt = pos["beschreibung"].split('\n').length - 1;
    let words = pos["beschreibung"].split(' ');
    let rowHeight = 8 + newLineCnt * 4

    let beschreibungStr = "";
    let beschreibungLineLen = 0;
    
    // todo what if word is longer than 50 chars

    for (let i = 0; i < words.length; i++) {
      beschreibungLineLen += words[i].length + 1;
      if (beschreibungLineLen > maxBeschreibungChars) {
        rowHeight += 4;
        beschreibungLineLen = 0;
        beschreibungStr += '\n' + words[i] + ' ';
      }
      else {
        beschreibungStr += words[i] + ' ';
      }
    }

    let rowEndPos = latestPosition + rowHeight;
    if ((rowEndPos) >= maxTableY) {

      footer(this, data);
      this.addPage();
      latestPosition = 20;
      this.setFontSize(13);
      this.setFont(undefined, 'bold');
      this.text(data["RechnungsNummerText"], 20, latestPosition);
      this.text(data["RechnungsNummerValue"], 77.3, latestPosition, 'right');
      latestPosition += 5;
      tableHeader(this, positions, latestPosition);
      latestPosition += 8;
    }

    createTableRow(this, pos["nummer"], beschreibungStr, pos["menge"], pos["einzelpreis"], pos["mwst"], pos["gesamtpreis"], latestPosition, rowHeight, counter++ % 2 === 0);
    latestPosition += rowHeight

  });

  if ((latestPosition + invoiceTotalSpace) >= maxTableY) {
    footer(this, data);
    this.addPage();
    latestPosition = 20;
    this.setFontSize(13);
    this.setFont(undefined, 'bold');
    this.text(data["RechnungsNummerText"], 20, latestPosition);
    this.text(data["RechnungsNummerValue"], 77.3, latestPosition, 'right');
    latestPosition += 5;
    tableHeader(this, positions, latestPosition);
    latestPosition += 8;
  }

  this.setLineWidth(0.5);
  this.line(22, latestPosition, 190, latestPosition);
  createTableRow(this, "", "Gesamtpreis netto:", "", "", "", total["net"], latestPosition, 8, false);
  latestPosition += 8;

  total["taxRates"].forEach(rates => {
    createTableRow(this, "", "zzgl. Umsatzsteuer " + rates["rate"], "", "", "", rates["rateTotal"], latestPosition, 8, false);
    latestPosition += 8;
  })

  createTableRow(this, "", "Gesamtpreis brutto:", "", "", "", total["gross"], latestPosition, 8, true, true);

  latestPosition += spaceAfterTable;

  this.text(20, latestPosition, data["Zahlungsbedingungen"]);
  latestPosition += ZahlungsbedingungenHeight; // todo latestPosition += height of Zahlungsbedingungen

  this.text(20, latestPosition, data["Bankverbindung"]);


  // this.text(X40, Y20, 'This is the second title.');

  footer(this, data);
  this.save("Invoice.pdf");
}

function makeInvoice(data, positions_unformated, total_unformated, rechnungsdatum, lieferzeitraum, logo) {
  var doc = new jsPDF();
  doc.page = 1;

  data["RechnungsDatumValue"] = new Date(rechnungsdatum).toLocaleDateString("de-DE");
  data["LieferzeitraumValue"] = new Date(lieferzeitraum[0]).toLocaleDateString("de-DE") + " - " + new Date(lieferzeitraum[1]).toLocaleDateString("de-DE");

  var positions = []

  var total = {
    net: formatEuro(total_unformated['net']).toString(),
    taxRates: [],
    gross: formatEuro(total_unformated['gross']).toString()
  }

  total_unformated["taxRates"].forEach(rate => {
    total["taxRates"].push(
      { rate: rate["rate"] + "%", rateTotal: formatEuro(rate["rateTotal"]).toString() }
    )
  })

  positions_unformated.forEach(pos => {
    positions.push({
      id: 0,
      nummer: pos['nummer'].toString(),
      beschreibung: pos['beschreibung'].toString(),
      menge: pos['menge'].toString(),
      einzelpreis: formatEuro(pos['einzelpreis']).toString(),
      mwst: pos['mwst'] + "%",
      gesamtpreis: formatEuro(pos['gesamtpreis']).toString()
    })
  });


  doc.generateInvoice(data, logo, positions, total);
}

export default makeInvoice;
