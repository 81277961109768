import React from 'react'
import { Helmet } from 'react-helmet'

export default function Impressum() {
  return (
    <div className=''>
      <Helmet>
        <title data-react-helmet="true">Impressum</title>
        <meta name="robots" content="noodp,noydir" />
      </Helmet>
      <div className='mx-4 sm:mx-36 my-12 h-full rounded-md drop-shadow-md bg-white'>

        <div className='text-center text-4xl font-bold font-mono p-16 underline underline-offset-8'>
          Impressum
        </div>

        <div className='mx-4 sm:px-32 pb-16'>

          <p>
            <b>
              S&S Technologies GmbH
            </b>
            <p>
              IT-Service Provider <br />
              UID-Nr: ATU77676212 <br />
              FN: 571385y <br />
              FB-Gericht: LG Salzburg
            </p>
            <b>
              Address:
            </b>
            <p>
              Haspingerstrasse 4 <br />
              5550 Radstadt <br />
              Salzburg, Austria <br />
            </p>
            <b> Contact </b>
            <p>
              office@ss-technologies.at <br />
              +43 (0) 677 6121 1681
            </p>
          </p>

        </div>

      </div>
    </div>
  )
}
