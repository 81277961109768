import React from 'react'
import { Helmet } from 'react-helmet'

export default function Hilfe() {
  return (
    <div className='mx-[5vw] my-12 px-4 h-full rounded-md drop-shadow-md bg-white flex items-center justify-center font-mono'>
      <Helmet>
        <title data-react-helmet="true">Wie erstelle ich eine Rechnung?</title>
        <meta
          name="description"
          content="Richtig Rechnungen schreiben mit unsererem Rechnungsgenerator. Folgen Sie den Angaben und erstellen Sie Schritt für Schritt Ihre eigene Rechnung."
          data-react-helmet="true"
        />
        <meta
          name="keywords"
          content="Rechnung erstellen, Rechnungsgenerator, Rechnung Vorlage, Rechnung richtig schreiben, Wie schreibt man eine Rechnung?"
          data-react-helmet="true"
        />
        <meta name="robots" content="noodp,noydir" />
      </Helmet>
      <div className='py-14 w-[80vw]'>
        <div className="text-4xl font-mono font-bold underline pb-4">
          Hilfe
        </div>
        <h1 className='text-2xl py-4 font-bold text-start'>
          Wie erstelle ich eine Rechnung?
        </h1>
        <ul className="font-mono text-md text-start">
          <li className='py-2'>1. Füllen Sie alle Felder des Rechnungsformulars aus</li>
          <li className='py-2'>2. Überprüfen Sie, ob alle <a href="/rechnungen-richtig-schreiben" className="underline">Pflichtangeben</a> in Ihrer Rechnung vorhanden sind</li>
          <li className='py-2'>3. Wählen Sie in der Schaltfläche <i>Rechnungsart</i> Ihre Unternehmensform aus</li>
          <li className='py-2'>4. Nachdem alle Felder ausgefüllt und kontrolliert wurden können Sie die Rechnung erstellen und herunterladen</li>
        </ul>
        <h2 className='text-2xl py-4 font-bold text-start'>
          Wie verwende ich Rechnungsvorlagen?
        </h2>
        <ul className="font-mono text-md text-start">
          <li className='py-2'>1. Erstellen Sie eine Rechnungsvorlagen</li>
          <li className='py-2'>2. Laden Sie die Vorlage hoch und vervollständigen Sie die fehlenden Fleder</li>
        </ul>
        <h2 className='text-2xl py-4 font-bold text-start'>
          Wie erstelle ich eine Rechnungsvorlage?
        </h2>
        <ul className="font-mono text-md text-start">
          <li className='py-2'>1. Füllen Sie alle Felder, die Sie wiederverwenden wollen, des Rechnungsformulars aus</li>
          <li className='py-2'>2. Überprüfen Sie, ob alle <a href="/rechnungen-richtig-schreiben" className="underline">Pflichtangeben</a> in Ihrer Rechnung vorhanden sind</li>
          <li className='py-2'>3. Nachdem alle Felder ausgefüllt und kontrolliert wurden können Sie die Rechnungsvorlage per <i>Vorlage Speichern</i> herunterladen und auf Ihrem Gerät speichern.</li>
        </ul>
        <h2 className='text-2xl py-4 font-bold text-start'>
          Wie lade ich eine Rechnungsvorlage hoch?
        </h2>
        <ul className="font-mono text-md text-start">
          <li className='py-2'>1. Wählen Sie in der Schaltfläche <i>Vorlage Hochladen</i> die Rechnungsvorlage auf Ihrem Computer aus</li>
          <li className='py-2'>2. Laden Sie die Vorlage hoch und vervollständigen Sie die fehlenden Fleder</li>
        </ul>
        <h2 className='text-2xl py-4 font-bold text-start'>
          Wie funktioniert der Rechnungsgenerator?
        </h2>
        <div className="font-mono text-md text-start pb-12">
          Der Rechnungsgenerator ist eine Web-Applikation, die in Javascript implementiert ist. Das bedeutet, dass alles lokal auf Ihrem Gerät passiert und keinerlei Daten an uns oder andere weitergeleitet werden.
        </div>
      </div>
    </div>
  )
}
