/* This example requires Tailwind CSS v2.0+ */
import { Disclosure, } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { Component } from 'react'
import { NavLink } from 'react-router-dom';

class Navbar extends Component {

    navigation = [
        { name: 'Rechnung erstellen!', href: '/' },
        { name: 'Was muss auf die Rechnung?', href: '/rechnungen-richtig-schreiben' },
        { name: 'Hilfe', href: '/hilfe' },
    ]

    classNames(...classes: any) {
        return classes.filter(Boolean).join(' ')
    }

    render() {
        return (
            <Disclosure as="nav" className="sticky z-50 top-0 bg-neutral-800 h-20 border-b-2 font-mono">
                {({ open }) => (
                    <>
                        {console.log()}
                        <div className="">
                            <div className="relative flex items-center justify-between h-20">

                                <div className="absolute inset-y-0 left-0 ml-4 flex items-center sm:hidden">
                                    <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                        {open ? (
                                            <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                        ) : (
                                            <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                        )}
                                    </Disclosure.Button>
                                </div>

                                <div className="absolute inset-y-0 right-0 flex items-center mr-4 sm:hidden">
                                    <a href='/' className="text-white text-xl font-bold">Rechnung-Schreiben.com</a>
                                </div>


                                <div className="flex-1 flex items-stretch justify-end sm:items-stretch sm:justify-start">
                                    <div className="hidden sm:block sm:ml-[5vw] md:ml-[10vw]">
                                        <div className="flex space-x-4">
                                            <a
                                                href='/'
                                                className='text-white px-3 py-2 text-2xl font-bold cursor-pointer'
                                            >
                                                Rechnung-Schreiben.com
                                            </a>

                                        </div>
                                    </div>
                                </div>

                                <div className="absolute inset-y-0 right-0 flex items-center sm:static  sm:ml-[5vw] sm:mr-[5vw] lg:mr-[10vw]">
                                    <div className="hidden sm:block">
                                        <div className="flex space-x-4">
                                            {this.navigation.map((item) => (
                                                <NavLink
                                                    key={item.name}
                                                    to={item.href}
                                                    className='px-3 py-2 rounded-md text-lg text-white font-medium hover:font-bold hover:underline'
                                                >
                                                    {item.name}
                                                </NavLink>
                                            ))}
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>

                        <Disclosure.Panel className="sm:hidden">
                            <div className="px-2 pt-2 pb-3 space-y-1">
                                {this.navigation.map((item) => (
                                    <NavLink
                                        key={item.name}
                                        to={item.href}
                                        className={(isActive) =>
                                            this.classNames(
                                                isActive ?
                                                    'block px-3 py-2 rounded-md text-base font-medium  bg-neutral-800 text-white' :
                                                    'block px-3 py-2 rounded-md text-base font-medium  bg-gray-100 text-gray-800 hover:bg-gray-700 hover:text-white'
                                            )}
                                    >
                                        <Disclosure.Button>
                                            {item.name}
                                        </Disclosure.Button>
                                    </NavLink>
                                ))}
                            </div>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        )
    }
}

export default Navbar